<script setup lang="ts">
import { computed, inject, ref, Ref } from 'vue';
import { IEventTimelineWeek } from '@/types/Event';
import { AppBox, AppBoxBody, AppButton, FontIcon, PlanningProgressMultiBar } from '@/components';
import useTime from '@/composables/useTime';
import { Tooltip } from 'floating-vue';
import { useI18n } from 'vue-i18n';

const { collapsed, data, clientUuid, loadingTasksToDone, weekNumber, userUuid } = defineProps<{
  collapsed: boolean;
  data: IEventTimelineWeek['tasks']['events'][0]['projects'][0];
  clientUuid: string;
  loadingTasksToDone: number[];
  weekNumber: string;
  userUuid: string;
}>();

const emit = defineEmits<{
  (e: 'toggle', projectId: number): void;
  (e: 'edit', taskId: number): void;
  (e: 'done', taskId: number): void;
}>();

const { convertMinutesToTime } = useTime();
const { d, t } = useI18n({ useScope: 'global' });

const hideDone = inject<Ref<number>>('hide_done', ref(0));
const dragging = ref(false);
const tasksDone = computed(() => data.tasks.every((task) => task.done_at));

function dragstartProject(dragEvent: DragEvent) {
  if (!dragEvent.dataTransfer) return;
  dragging.value = true;
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('from_week', weekNumber);
  dragEvent.dataTransfer.setData('source', 'project');
  dragEvent.dataTransfer.setData('start_date', data.start_date);
  dragEvent.dataTransfer.setData('end_date', data.end_date);
  dragEvent.dataTransfer.setData(
    'events',
    data.tasks
      .filter((task) => task.done_at === null)
      .map(({ id }) => id)
      .join(','),
  );
}

function dragendProject() {
  dragging.value = false;
}

function dragstartEvent(
  dragEvent: DragEvent,
  task: IEventTimelineWeek['tasks']['events'][0]['projects'][0]['tasks'][0],
) {
  dragEvent.stopPropagation();
  if (!dragEvent.dataTransfer) return;
  (dragEvent.target as HTMLDivElement).classList.add('dragging');
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('from_week', weekNumber);
  dragEvent.dataTransfer.setData('start_date', data.start_date);
  dragEvent.dataTransfer.setData('end_date', data.end_date);
  dragEvent.dataTransfer.setData('source', 'project_task');
  dragEvent.dataTransfer.setData('events', task.id.toString());
}

function dragendEvent(dragEvent: DragEvent) {
  dragEvent.stopPropagation();
  (dragEvent.target as HTMLDivElement).classList.remove('dragging');
}
</script>

<template>
  <AppBox
    @dragstart="dragstartProject"
    @dragend="dragendProject"
    :draggable="!tasksDone"
    shadow
    v-if="!(hideDone === 1 && tasksDone)"
    :class="{ dragging }"
  >
    <AppBoxBody>
      <div class="row d-flex align-items-center">
        <div class="col-3">
          <Tooltip class="d-inline-block" placement="right">
            <RouterLink
              custom
              :to="{ name: 'projects.edit', params: { uuid: clientUuid, id: data.id } }"
              v-slot="{ href, navigate }"
            >
              <a
                draggable="false"
                class="word-break font-bold text-5 text-neutral-900 pl-2"
                :href="href"
                target="_blank"
                @click="navigate"
              >
                {{ data.name }}
              </a>
            </RouterLink>
            <template #popper>
              <div class="popper-timeline">
                <p v-if="data.responsible">
                  <strong>{{ t('event.timeline.project.responsible') }}: </strong>
                  {{ data.responsible.name }}
                </p>
                <p v-if="data.team_members.filter(({ uuid }) => uuid !== userUuid).length">
                  <strong>{{ t('event.timeline.project.other_team_members') }}: </strong>
                  {{
                    data.team_members
                      .filter(({ uuid }) => uuid !== userUuid)
                      .map(({ name }) => name)
                      .join(', ')
                  }}
                </p>
                <p v-if="data.financial_year">
                  <strong>{{ t('event.timeline.project.financial_year') }}: </strong>
                  {{ d(data.financial_year.start_date) }} - {{ d(data.financial_year.end_date) }}
                </p>
                <p>
                  <strong>{{ t('event.timeline.project.dates') }}: </strong>
                  {{ d(data.start_date) }} - {{ d(data.end_date) }}
                </p>
              </div>
            </template>
          </Tooltip>
        </div>
        <div class="col-1 text-right">
          <AppButton size="small" @click.prevent="emit('toggle', data.id)" light circle>
            <FontIcon v-if="collapsed" name="chevron-down" />
            <FontIcon v-else name="chevron-up" />
          </AppButton>
        </div>
        <div class="col-1 text-center">
          <strong class="text-5" v-text="convertMinutesToTime(data.time.budgeted)" />
        </div>
        <div class="col-1">
          <FontIcon v-if="tasksDone" class="text-success-500 text-2 ml-3" name="check" />
        </div>
        <div class="col-6">
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </div>
      </div>
      <div class="mt-2" v-if="!collapsed">
        <div
          class="row align-items-center py-3"
          :draggable="!task.done_at"
          v-for="(task, index) in data.tasks"
          :key="index"
          :class="[{ draggable: !task.done_at }, hideDone === 1 && task.done_at ? 'd-none' : 'd-flex']"
          @dragstart="dragstartEvent($event, task)"
          @dragend="dragendEvent"
        >
          <div class="col-4 font-bold">
            <div @click.prevent="emit('edit', task.id)" class="pointer text-secondary-500 text-5 pl-2">
              <Tooltip
                class="d-inline-block"
                placement="right"
                :disabled="task.deadline === null && task.note === null"
              >
                <span>{{ task.name }}</span>
                <template #popper>
                  <div class="popper-timeline">
                    <p v-if="task.note">
                      <strong>{{ t('event.timeline.task.note') }}: </strong>
                      {{ task.note }}
                    </p>
                    <p v-if="task.deadline">
                      <strong>{{ t('event.timeline.task.deadline') }}: </strong>
                      {{ d(task.deadline) }}
                    </p>
                  </div>
                </template>
              </Tooltip>
              <template v-if="task.sub_label"> - {{ task.sub_label }} </template>
            </div>
          </div>
          <div class="col-1 text-center">
            <strong class="text-5" v-text="convertMinutesToTime(task.time.budgeted)" />
          </div>
          <div class="col-1">
            <FontIcon v-if="task.done_at" class="text-success-500 text-2 ml-3" name="check" />
          </div>
          <div class="col-6 d-flex align-items-center">
            <div class="flex-grow-1">
              <PlanningProgressMultiBar
                :green="task.time.finished"
                :orange="task.time.reported"
                :gray="Math.max(task.time.remaining, 0)"
              />
            </div>
            <div v-if="!task.done_at" class="flex-shrink-0 pl-2">
              <AppButton
                color="success"
                circle
                light
                size="small"
                @click.prevent="emit('done', task.id)"
                :loading="loadingTasksToDone.includes(task.id)"
              >
                <FontIcon name="check" />
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </AppBoxBody>
  </AppBox>
</template>
