export default {
  index: {
    title: 'Min veckoöversikt',
    create: 'Rapportera tid',
    task: 'Uppgifter & notifieringar',
    week_one_letter: 'v',
    firm_activities: 'Interna aktiviteter',
    info: 'Info',
    hide_done: 'Dölj klarmarkerade',
    show_all_tasks: 'Visa alla uppgifter',
  },
  buttons: {
    load_prev_week: 'Visa föregående vecka',
    load_next_week: 'Visa nästa vecka',
  },
  timesheet: {
    timesheet: 'Tidkort',
    summary: 'Summa',
  },
};
