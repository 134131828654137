import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Users from '@/views/Users/Users.vue';
import User from '@/views/Users/User.vue';
import useAuthStore from '@/store/AuthStore';
import progress from '@/services/progress';
import useDayDurationStore from '@/store/DayDurationStore';

const prefix = 'users';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Users,
  },
  {
    path: `/${prefix}/edit/:uuid`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    props: true,
    component: User,
    async beforeEnter(_to, _from, next) {
      const { isCustomerAdminRole, isSuperAdminRole } = useAuthStore();
      if (isCustomerAdminRole || isSuperAdminRole) {
        progress.start();
        const { getDayDuration } = useDayDurationStore();
        await getDayDuration();
        progress.done();
        return next();
      }
      return next({ name: 'dashboard' });
    },
  },
];

export default routes;
