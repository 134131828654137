import accounting from './accounting';
import activities from './activities';
import articles from './articles';
import auth from './auth';
import businessDay from './business-day';
import checkpoint from './checkpoint';
import client from './client';
import clientVisit from './client-visit';
import common from './common';
import companyCategories from './company-categories';
import contact from './contact';
import coworkers from './coworkers';
import dashboard from './dashboard';
import deadline from './deadline';
import department from './department';
import event from './event';
import expense from './expense';
import financialYear from './financial-year';
import freeDay from './free-day';
import internalProjects from './internal-projects';
import invoice from './invoice';
import invoiceItemTemplate from './invoice-item-template';
import navigation from './navigation';
import office from './office';
import priceMatrix from './price-matrix';
import project from './project';
import salary from './salary';
import search from './search';
import service from './service';
import serviceCompany from './service-company';
import serviceMapping from './service-mapping';
import task from './task';
import timeEntry from './time-entry';
import timePeriod from './time-period';
import timeSheet from './time-sheet';
import todo from './todo';
import user from './user';
import validation from './validation';
import workTitle from './work-title';

export default {
  'company-categories': companyCategories,
  'client-visit': clientVisit,
  'business-day': businessDay,
  'financial-year': financialYear,
  'free-day': freeDay,
  'internal-projects': internalProjects,
  'invoice-item-template': invoiceItemTemplate,
  'price-matrix': priceMatrix,
  'service-company': serviceCompany,
  'service-mapping': serviceMapping,
  'time-entry': timeEntry,
  'time-period': timePeriod,
  'time-sheet': timeSheet,
  'work-title': workTitle,
  accounting,
  activities,
  articles,
  auth,
  checkpoint,
  client,
  common,
  contact,
  coworkers,
  dashboard,
  deadline,
  department,
  event,
  expense,
  invoice,
  navigation,
  office,
  project,
  salary,
  search,
  service,
  task,
  todo,
  user,
  validation,
};
