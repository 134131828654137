<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { useModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import sniCodes from '../../utils/sni-codes';

import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppPagination,
  AppLoader,
  AppCollapse,
  ColumnSettings,
  HelpInformation,
  ConfirmModal,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import { IOfficeListResource } from '@/types/Office';
import { IServicePreviewResource } from '@/types/Service';
import { IServiceCompanyContactPreviewResource, IServiceCompanyListResource } from '@/types/ServiceCompany';
import { IUserListResource } from '@/types/User';
import { IClientListResource, IClientPreviewResource, IClientTagResource } from '@/types/Client';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ProjectStep } from '@/types/Project';
import useClients from '@/composables/useClients';
import { useTitle } from '@vueuse/core';

const router = useRouter();
const route = useRoute();
const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { clients: clientsOptions, onSearchClients } = useClients();

const clients = ref<IClientListResource[]>([]);

// Pagination
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('clients_columns', [
  'industry',
  'group_tag',
  'city',
  'end_financial_year_date',
  'service_companies',
  'service_company_contacts',
  'active_services',
  'active_projects',
  'project_responsible',
  'office',
]);

// Filters
const filterLoader = useLoader();
const filterContact = useRouteQuery('contact', '');
const filterClient = ref<string>('');
const filterIndustries = ref<string[]>([]);
const filterGroupTag = ref<string>('');
const filterCity = ref<string>('');
const filterFiscalYearEnd = ref<string>('');
const filterServiceOrganisation = useRouteQuery<string>('service_company', '');
const filterServiceOrganisationContact = useRouteQuery<string>('service_company_contact', '');
const filterServices = ref<string[]>([]);
const filterProjectName = ref<string>('');
const filterTeamMember = ref<string>('');
const filterOffice = ref<string>('');
const filterShowAll = useRouteQuery<number>('show_all', 0, { transform: Number });
const filterShowOnlyActive = useRouteQuery<number>('show_only_active', 1, { transform: Number });
const filterShowConnectedCompanies = ref<number>(0);

async function resetFilters() {
  filterClient.value = '';
  filterIndustries.value = [];
  filterGroupTag.value = '';
  filterCity.value = '';
  filterFiscalYearEnd.value = '';
  filterServiceOrganisation.value = '';
  filterServiceOrganisationContact.value = '';
  filterServices.value = [];
  filterProjectName.value = '';
  filterTeamMember.value = '';
  filterOffice.value = '';
  filterShowAll.value = 0;
  filterShowOnlyActive.value = 1;
  filterShowConnectedCompanies.value = 0;
  filterContact.value = '';
  await onFilter();
}

function onDelete(client: IClientListResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('client.confirm.destroy.title'),
      message: t('client.confirm.destroy.text', {
        name: client.name,
        org_number: client.org_number,
      }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        setLoading(true);
        try {
          await api.clients.destroy(client.uuid);
          resetFilters();
          await getClients();
          if (clients.value.length === 0 && page.value > 1) {
            page.value -= 1;
          }
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function getClients(loadable = false) {
  if (loadable) {
    loader.start();
  }
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('sort_by', 'name');
    searchParams.append('sort_dir', 'asc');

    if (filterContact.value) searchParams.append('contact', filterContact.value);

    // Set filters
    if (filterClient.value) searchParams.append('client', filterClient.value);
    filterIndustries.value.forEach((industry) => searchParams.append('industries[]', industry));
    if (filterGroupTag.value) searchParams.append('group_tag', filterGroupTag.value);
    if (filterCity.value) searchParams.append('city', filterCity.value);
    if (filterFiscalYearEnd.value) searchParams.append('fiscal_year_end', filterFiscalYearEnd.value);
    if (filterServiceOrganisation.value) searchParams.append('service_organisation', filterServiceOrganisation.value);
    if (filterServiceOrganisationContact.value)
      searchParams.append('service_organisation_contact', filterServiceOrganisationContact.value);
    filterServices.value.forEach((service) => searchParams.append('services[]', service));
    if (filterProjectName.value) searchParams.append('project_name', filterProjectName.value);
    if (filterTeamMember.value) searchParams.append('team_member', filterTeamMember.value);
    if (filterOffice.value) searchParams.append('office', filterOffice.value);
    searchParams.append('show_all', filterShowAll.value.toString());
    searchParams.append('show_only_active', filterShowOnlyActive.value.toString());
    searchParams.append('show_connected_companies', filterShowConnectedCompanies.value.toString());

    const response = await api.clients.list({ searchParams });
    clients.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    if (loadable) {
      loader.finish();
    }
  }
}

async function onFilter() {
  filterLoader.start();
  page.value = 1;
  await getClients();
  filterLoader.finish();
}

// Client tags
const clientTags = ref<IClientTagResource[]>([]);
const clientTagsLoading = ref(false);

async function getClientTags() {
  try {
    clientTagsLoading.value = true;
    const response = await api.clients.tags.index();
    clientTags.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    clientTagsLoading.value = false;
  }
}

// Offices
const offices = ref<IOfficeListResource[]>([]);
const officesLoading = ref(false);

async function getOffices() {
  try {
    officesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.offices.list({ searchParams });
    offices.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    officesLoading.value = false;
  }
}

// Services
const services = ref<IServicePreviewResource[]>([]);
const servicesLoading = ref(false);

async function getServices() {
  try {
    servicesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.services.list({ searchParams });
    services.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    servicesLoading.value = false;
  }
}

// Service companies
const serviceCompanies = ref<IServiceCompanyListResource[]>([]);
const serviceCompaniesLoading = ref(false);

async function getServiceCompanies() {
  try {
    serviceCompaniesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.serviceCompanies.list({ searchParams });
    serviceCompanies.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    serviceCompaniesLoading.value = false;
  }
}

// Service company contacts
const serviceCompanyContacts = ref<IServiceCompanyContactPreviewResource[]>([]);
const serviceCompanyContactsLoading = ref(false);

async function onServiceCompaniesChange() {
  filterServiceOrganisationContact.value = '';
  await getServiceCompanyContacts(filterServiceOrganisation.value);
}

async function getServiceCompanyContacts(companyUuid: string) {
  if (!companyUuid) {
    return;
  }
  try {
    serviceCompanyContactsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.serviceCompanies.contacts.list(companyUuid, searchParams);
    serviceCompanyContacts.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    serviceCompanyContactsLoading.value = false;
  }
}

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  console.log();
  await Promise.all([
    getClientTags(),
    getOffices(),
    getServices(),
    getServiceCompanies(),
    getServiceCompanyContacts(filterServiceOrganisation.value),
    getUsers(),
    Object.keys(route.query).length > 0 ? getClients(true) : void 0,
  ]);
});

watch(page, async () => {
  loader.start();
  await getClients();
  loader.finish();
});

const title = useTitle(computed(() => t('client.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="client.index.help" />
      </div>
      <AppButton class="ml-auto" @click.prevent="router.push({ name: 'clients.create' })" color="secondary">
        {{ t('client.index.create') }}
      </AppButton>
      <Dropdown placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`client.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse :opened="true" class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-2xl-6 align-items-end"
        >
          <!-- Customer name or number -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_client">
              {{ t('client.index.filters.name_or_number') }}
            </FormLabel>
            <VueSelect
              v-model="filterClient"
              :options="clientsOptions"
              :reduce="(option: IClientPreviewResource) => option.uuid"
              label="name"
              input-id="filter-client"
              :filterable="false"
              @search="onSearchClients"
              :placeholder="t('common.search')"
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
          <!-- Industries -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_industries">
              {{ t('client.index.filters.industries') }}
            </FormLabel>
            <VueSelect
              v-model="filterIndustries"
              :options="sniCodes"
              :reduce="(option: typeof sniCodes[0]) => option.code.toString()"
              :get-option-label="(option: typeof sniCodes[0]) => `${option.code} ${option.text}`"
              input-id="filter_industries"
              multiple
            />
          </div>
          <!-- Group tag -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_group_tag">
              {{ t('client.index.filters.group_tag') }}
            </FormLabel>
            <VueSelect
              v-model="filterGroupTag"
              :options="clientTags"
              :reduce="(option: IClientTagResource) => option.id.toString()"
              label="name"
              input-id="filter_group_tag"
            />
          </div>
          <!-- City -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_city">
              {{ t('client.index.filters.city') }}
            </FormLabel>
            <FormInput type="search" v-model.trim="filterCity" id="filter_city" icon="search" />
          </div>
          <!-- Fiscal year end -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="dp-input-filter_fiscal_year_end">
              {{ t('client.index.filters.fiscal_year_end') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_fiscal_year_end"
                input-class-name="form-control"
                v-model="filterFiscalYearEnd"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Service org -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_service_organisation">
              {{ t('client.index.filters.service_organisation') }}
            </FormLabel>
            <VueSelect
              v-model="filterServiceOrganisation"
              :options="serviceCompanies"
              :reduce="(option: IServiceCompanyListResource) => option.uuid"
              label="name"
              input-id="filter_service_organisation"
              :loading="serviceCompaniesLoading"
              :disabled="serviceCompaniesLoading"
              :clearable="!serviceCompaniesLoading"
              @update:model-value="onServiceCompaniesChange"
            >
              <template #selected-option="option: IServiceCompanyListResource">
                <span>{{ serviceCompaniesLoading ? '' : option.name }}</span>
              </template>
            </VueSelect>
          </div>
          <!-- Service org contact -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_service_organisation_contact">
              {{ t('client.index.filters.service_organisation_contact') }}
            </FormLabel>
            <VueSelect
              v-model="filterServiceOrganisationContact"
              :options="serviceCompanyContacts"
              :reduce="(option: IServiceCompanyContactPreviewResource) => option.uuid"
              label="name"
              input-id="filter_service_organisation_contact"
              :loading="serviceCompanyContactsLoading"
              :disabled="serviceCompanyContactsLoading"
              :clearable="!serviceCompanyContactsLoading"
            >
              <template #selected-option="option: IServiceCompanyContactPreviewResource">
                <span>{{ serviceCompanyContactsLoading ? '' : option.name }}</span>
              </template>
            </VueSelect>
          </div>
          <!-- Services -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_services">
              {{ t('client.index.filters.services') }}
            </FormLabel>
            <VueSelect
              v-model="filterServices"
              :options="services"
              :reduce="(option: IServicePreviewResource) => option.uuid"
              label="name"
              input-id="filter_services"
              multiple
              :loading="servicesLoading"
            />
          </div>
          <!-- Project name -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_project_name">
              {{ t('client.index.filters.project_name') }}
            </FormLabel>
            <FormInput type="search" v-model.trim="filterProjectName" id="filter_project_name" icon="search" />
          </div>
          <!-- Team member -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_team_member">
              {{ t('client.index.filters.team_member') }}
            </FormLabel>
            <VueSelect
              v-model="filterTeamMember"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter_team_member"
              :loading="usersLoading"
            />
          </div>
          <!-- Office -->
          <div class="form-group col col-2xl col-6xl">
            <FormLabel html-for="filter_office">
              {{ t('client.index.filters.office') }}
            </FormLabel>
            <VueSelect
              v-model="filterOffice"
              :options="offices"
              :reduce="(option: IOfficeListResource) => option.id.toString()"
              :get-option-label="(option: IOfficeListResource) => option.name"
              input-id="filter_office"
              :loading="officesLoading"
            />
          </div>
        </div>
        <div class="row row-cols-1">
          <!-- Show all -->
          <div class="form-group col">
            <input
              id="filter_show_all"
              type="checkbox"
              class="form-check"
              v-model="filterShowAll"
              :true-value="1"
              :false-value="0"
            />
            <label for="filter_show_all" class="form-label" v-text="t('client.index.filters.show_all')" />
          </div>
          <!-- Show only active -->
          <div class="form-group col">
            <input
              id="filter_show_only_active"
              type="checkbox"
              class="form-check"
              v-model="filterShowOnlyActive"
              :true-value="1"
              :false-value="0"
            />
            <label
              for="filter_show_only_active"
              class="form-label"
              v-text="t('client.index.filters.show_only_active')"
            />
          </div>
          <!-- Show connected companies -->
          <!-- TODO add this filter later when backend is ready -->
          <div v-if="false" class="form-group col">
            <input
              id="filter_show_connected_companies"
              type="checkbox"
              class="form-check"
              v-model="filterShowConnectedCompanies"
              :true-value="1"
              :false-value="0"
            />
            <label
              for="filter_show_connected_companies"
              class="form-label"
              v-text="t('client.index.filters.show_connected_companies')"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="filterLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value || filterLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="clients.length === 0">
        {{ t('client.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('client.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('industry')">
                {{ t('client.attributes.industry') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('group_tag')">
                {{ t('client.attributes.group_tag') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('city')">
                {{ t('client.attributes.city') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('end_financial_year_date')">
                {{ t('client.attributes.end_financial_year_date') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('service_companies')">
                {{ t('client.attributes.service_companies') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('service_company_contacts')">
                {{ t('client.attributes.service_company_contacts') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('active_services')">
                {{ t('client.attributes.active_services') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('active_projects')">
                {{ t('client.attributes.active_projects') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('project_responsible')">
                {{ t('client.attributes.project_responsible') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('office')">
                {{ t('client.attributes.office') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              @click="router.push({ name: 'client.overview', params: { uuid: client.uuid } })"
              class="pointer"
              v-for="client in clients"
              :key="client.uuid"
            >
              <AppTableTd nowrap>
                <strong v-text="client.name" />
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('industry')">{{ client.industry }}</AppTableTd>
              <AppTableTd v-if="isColumnEnable('group_tag')">
                <div v-for="tag in client.tags" :key="tag.id" class="text-nowrap">
                  {{ tag.name }}
                </div>
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('city')">{{ client.city }}</AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('end_financial_year_date')">
                {{ client.fiscal_year_end }}
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('service_companies')">
                <div v-for="serviceCompany in client.serviceCompanies" :key="serviceCompany.uuid" class="text-nowrap">
                  {{ serviceCompany.name }}
                </div>
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('service_company_contacts')">
                <div
                  v-for="serviceCompanyContact in client.serviceCompanyContacts"
                  :key="serviceCompanyContact.uuid"
                  class="text-nowrap"
                >
                  {{ serviceCompanyContact.name }}
                </div>
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('active_services')">
                <div v-for="activeService in client.activeServices" :key="activeService.uuid" class="text-nowrap">
                  {{ activeService.name }}
                </div>
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('active_projects')">
                <div v-for="activeProject in client.activeProjects" :key="activeProject.id" class="text-nowrap">
                  <RouterLink
                    @click.native.stop="(event: MouseEvent) => event.stopPropagation()"
                    target="_blank"
                    :to="{
                      name: 'projects.edit',
                      params: { uuid: client.uuid, id: activeProject.id },
                      query: { step: ProjectStep.Planning },
                    }"
                    class="pointer"
                  >
                    {{ activeProject.name }}
                  </RouterLink>
                </div>
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('project_responsible')">
                <div
                  v-for="projectResponsibleItem in client.projectResponsible"
                  :key="projectResponsibleItem.uuid"
                  class="text-nowrap"
                >
                  {{ projectResponsibleItem.name }}
                </div>
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('office')"
                >{{ client.office ? client.office.name : '' }}
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-tooltip.left="t('client.tooltip.edit', { name: client.name })"
                  @click.stop="router.push({ name: 'client.overview', params: { uuid: client.uuid } })"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('client.tooltip.projects', { name: client.name })"
                  @click.stop="router.push({ name: 'projects.index', params: { uuid: client.uuid } })"
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="briefcase" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('client.tooltip.destroy', { name: client.name })"
                  @click.stop="onDelete(client)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
