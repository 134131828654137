import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IContactListResource, IContactUpdateRequest } from '@/types/Contact';
import { IContactSearchResource } from '@/types/Client';

export default {
  index(options: Options = {}) {
    return httpClient.get(route('contacts.getList'), options).json<IResponse<IContactListResource[]>>();
  },
  async search(search: string): Promise<IResponse<IContactSearchResource[]>> {
    return httpClient.get(route('contacts.search'), { searchParams: { search } }).json();
  },
  update(contact: string, json: IContactUpdateRequest, options: Options = {}) {
    return httpClient
      .patch(route('contacts.update', { contact }), { ...options, json })
      .json<IResponse<IContactListResource>>();
  },
  delete(contact: string, options: Options = {}) {
    return httpClient.delete(route('contacts.delete', { contact }), options).json<void>();
  },
};
