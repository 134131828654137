<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { DateTime } from 'luxon';
import route from 'ziggy-js';

import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  FontIcon,
  FormInput,
  FormLabel,
  HelpInformation,
  UserPriceModal,
  UserStatus,
  UserWorkRatesModal,
} from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import authStore from '@/store/AuthStore';
import {
  IUserListResource,
  IUserPriceChangeRequest,
  IUserResource,
  IUserUpdateRequest,
  UserPriceType,
  UserRole,
  UserSystemRole,
} from '@/types/User';
import { IOfficeListResource } from '@/types/Office';
import { IDepartmentResource } from '@/types/Department';
import { IWorkTitleResource } from '@/types/WorkTitle';
import { useTitle } from '@vueuse/core';
import useWorkTitles from '@/composables/useWorkTitles';
import useOffices from '@/composables/useOffices';
import useDepartments from '@/composables/useDepartments';
import useTimePeriods from '@/composables/useTimePeriods';
import useUsers from '@/composables/useUsers';

type Props = {
  uuid: string;
};

const { uuid } = defineProps<Props>();

const user = ref<null | IUserResource>(null);

const form = reactive<IUserUpdateRequest>({
  office_id: null,
  department_id: null,
  manager_uuid: null,
  work_title_id: null,
});

const internalPriceExist = ref(false);
const externalPriceExist = ref(false);

const workRateLoader = useLoader();

const { isCustomerAdminRole, isSuperAdminRole } = authStore();

const editable = computed(() => isCustomerAdminRole || isSuperAdminRole);

const disabled = computed(() => !(form.office_id && form.department_id && form.manager_uuid && form.work_title_id));

const loader = useLoader({ useProgress: false });
const submitLoader = useLoader();
const router = useRouter();
const { workTitles, workTitlesLoading, getWorkTitles } = useWorkTitles();
const { offices, officesLoading, getOffices } = useOffices();
const { departments, departmentsLoading, getDepartments } = useDepartments();
const { timePeriods, getTimePeriods } = useTimePeriods();
const { users, usersLoading, getUsers } = useUsers();
const { t } = useI18n({ useScope: 'global' });

async function getEmployee() {
  try {
    const response = await api.users.get(uuid);
    user.value = response.data;
    // Set form
    form.office_id = response.data.office?.id ?? null;
    form.department_id = response.data.department?.id ?? null;
    form.work_title_id = response.data.work_title?.id ?? null;
    form.manager_uuid = response.data.manager?.uuid ?? null;

    internalPriceExist.value = response.data.internal_cost !== null;
    externalPriceExist.value = response.data.hourly_price !== null;
  } catch (error) {
    console.error(error);
  }
}

async function updateWorkRate() {
  workRateLoader.start();
  try {
    const response = await api.users.get(uuid);
    if (user.value) {
      user.value.work_rate_percent = response.data.work_rate_percent;
    }
  } catch (error) {
    console.error(error);
  } finally {
    workRateLoader.finish();
  }
}

async function submit() {
  try {
    submitLoader.start();
    const response = await api.users.update(uuid, form);
    user.value = response.data;

    delete form.internal_cost;
    delete form.hourly_price;

    internalPriceExist.value = response.data.internal_cost !== null;
    externalPriceExist.value = response.data.hourly_price !== null;
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

function onInternalPriceEdit() {
  if (user.value === null) return;
  const { open, close, destroy } = useModal({
    component: UserPriceModal,
    attrs: {
      editable: editable.value,
      type: UserPriceType.INTERNAL,
      history: user.value.internal_price_changes,
      initialDate: form.internal_cost
        ? form.internal_cost.apply_date
        : user.value.internal_cost
        ? null
        : DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      initialPrice: form.internal_cost?.price ?? null,
      disabledDate: !internalPriceExist.value,
      disabledPrice: false,
      onApply(userPrice: IUserPriceChangeRequest) {
        form.internal_cost = userPrice;
        if (user.value && !internalPriceExist.value) {
          user.value.internal_cost = userPrice.price;
        }
        close();
      },
      onDeleted(id: number) {
        if (user.value) {
          user.value.internal_price_changes = user.value.internal_price_changes.filter((price) => price.id !== id);
        }
      },
      onClear() {
        delete form.internal_cost;
        if (user.value && !internalPriceExist.value) {
          user.value.internal_cost = null;
        }
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onExternalPriceEdit() {
  if (user.value === null) return;
  const { open, close, destroy } = useModal({
    component: UserPriceModal,
    attrs: {
      editable: editable.value,
      type: UserPriceType.EXTERNAL,
      history: user.value.external_price_changes,
      initialDate: form.hourly_price
        ? form.hourly_price.apply_date
        : user.value.hourly_price
        ? null
        : DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      initialPrice: form.hourly_price?.price ?? null,
      disabledDate: !externalPriceExist.value,
      disabledPrice: false,
      onApply(userPrice: IUserPriceChangeRequest) {
        form.hourly_price = userPrice;
        if (user.value && !externalPriceExist.value) {
          user.value.hourly_price = userPrice.price;
        }
        close();
      },
      onDeleted(id: number) {
        if (user.value) {
          user.value.external_price_changes = user.value.external_price_changes.filter((price) => price.id !== id);
        }
      },
      onClear() {
        delete form.hourly_price;
        if (user.value && !externalPriceExist.value) {
          user.value.hourly_price = null;
        }
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onWorkRateEdit() {
  const { open, destroy } = useModal({
    component: UserWorkRatesModal,
    attrs: {
      userUuid: uuid,
      editable: editable.value,
      timePeriods: timePeriods.value,
      onClosed() {
        updateWorkRate();
        destroy();
      },
    },
  });
  open();
}

onMounted(async () => {
  loader.start();
  await Promise.all([
    getEmployee(),
    getDepartments({
      searchParams: {
        without_pagination: 1,
      },
    }),
    getOffices({
      searchParams: {
        without_pagination: 1,
      },
    }),
    getWorkTitles({
      searchParams: {
        without_pagination: 1,
      },
    }),
    getTimePeriods({
      searchParams: {
        without_pagination: 1,
      },
    }),
    getUsers(
      {
        searchParams: {
          without_pagination: 1,
        },
      },
      {
        onSet(rawUsers) {
          return rawUsers.some((user) => user.system_role === UserSystemRole.Manager)
            ? rawUsers.filter((user) => user.system_role === UserSystemRole.Manager)
            : rawUsers.filter((user) => user.role === UserRole.CustomerAdmin);
        },
      },
    ),
  ]);
  loader.finish();
});

const title = useTitle(computed(() => t('user.edit.title', { name: user.value?.name ?? '' })));
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-if="loader.isNotLoading.value && user" class="container-wide">
    <div class="d-flex align-items-center mb-4">
      <AppButton
        @click.prevent="router.push({ name: 'users.index' })"
        color="secondary"
        circle
        light
        v-tooltip="t('common.back')"
      >
        <FontIcon name="arrow-back-up" />
      </AppButton>
      <div class="d-flex align-items-end ml-3">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" :translation="'user.edit.help'" />
      </div>
    </div>
    <form @submit.prevent="submit">
      <AppBox shadow>
        <AppBoxBody>
          <div class="row">
            <div class="col-md-6">
              <h2 v-text="t('user.form.info_title')" />
              <!-- Name -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="name">{{ t('user.attributes.name') }}</FormLabel>
                </div>
                <div class="col">
                  <FormInput id="name" v-model="user.name" disabled />
                </div>
              </div>
              <!-- Personal number -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="personal_number">
                    {{ t('user.attributes.personal_number') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <FormInput id="personal_number" v-model="user.personal_number" disabled />
                </div>
              </div>
              <!-- Phone number -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="phone_number">{{ t('user.attributes.phone_number') }}</FormLabel>
                </div>
                <div class="col">
                  <FormInput id="phone_number" v-model="user.phone_number" disabled />
                </div>
              </div>
              <!-- Work title -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="work_title" required>
                    {{ t('user.attributes.work_title') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <VueSelect
                    :clearable="false"
                    v-model="form.work_title_id"
                    :reduce="(option: IWorkTitleResource) => option.id"
                    :options="workTitles"
                    label="name"
                    input-id="work_title"
                    :placeholder="t('common.select')"
                    :loading="workTitlesLoading"
                  />
                </div>
              </div>
              <!-- Email -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="email">{{ t('user.attributes.email') }}</FormLabel>
                </div>
                <div class="col">
                  <FormInput id="email" v-model="user.email" disabled />
                </div>
              </div>
              <!-- Office -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="office" required>
                    {{ t('user.attributes.office') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <VueSelect
                    :clearable="false"
                    v-model="form.office_id"
                    :reduce="(option: IOfficeListResource) => option.id"
                    :options="offices"
                    label="name"
                    input-id="office"
                    :placeholder="t('common.select')"
                    :loading="officesLoading"
                  />
                </div>
              </div>
              <!-- Department -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="department" required>
                    {{ t('user.attributes.department') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <VueSelect
                    :clearable="false"
                    v-model="form.department_id"
                    :options="departments"
                    :reduce="(option: IDepartmentResource) => option.id"
                    label="name"
                    input-id="department"
                    :placeholder="t('common.select')"
                    :loading="departmentsLoading"
                  />
                </div>
              </div>
              <!-- Manager -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="manager" required>
                    {{ t('user.attributes.manager') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <VueSelect
                    :clearable="false"
                    v-model="form.manager_uuid"
                    :options="users"
                    :reduce="(option: IUserListResource) => option.uuid"
                    label="name"
                    input-id="manager"
                    :placeholder="t('common.select')"
                    :loading="usersLoading"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h2 v-text="t('user.form.settings_title')" />
              <!-- External price -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="current_external_price_title" required>
                    {{ t('user.attributes.hourly_price') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <FormInput
                      group-class="flex-grow-1"
                      type="number"
                      id="current_external_price_title"
                      icon="currency-krone-swedish"
                      required
                      v-model="user.hourly_price"
                      readonly
                    />
                    <AppButton
                      class="flex-shrink-0 ml-2"
                      v-tooltip.left="t('common.edit')"
                      light
                      circle
                      @click.prevent="onExternalPriceEdit"
                    >
                      <FontIcon :name="editable ? 'pencil' : 'eye'" />
                    </AppButton>
                  </div>
                </div>
              </div>
              <!-- Internal price -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="current_internal_price_title" required>
                    {{ t('user.attributes.internal_price') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <FormInput
                      group-class="flex-grow-1"
                      type="number"
                      id="current_internal_price_title"
                      icon="currency-krone-swedish"
                      required
                      v-model="user.internal_cost"
                      readonly
                    />
                    <AppButton
                      class="flex-shrink-0 ml-2"
                      v-tooltip.left="t('common.edit')"
                      light
                      circle
                      @click.prevent="onInternalPriceEdit"
                    >
                      <FontIcon :name="editable ? 'pencil' : 'eye'" />
                    </AppButton>
                  </div>
                </div>
              </div>
              <!-- Work rate -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="work_rate" required>
                    {{ t('user.attributes.work_rate') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <FormInput
                      group-class="flex-grow-1"
                      min="0"
                      max="100"
                      step="1"
                      type="number"
                      id="work_rate"
                      icon="percentage"
                      v-model="user.work_rate_percent"
                      required
                      readonly
                      :disabled="workRateLoader.isLoading.value"
                    />
                    <AppButton
                      class="flex-shrink-0 ml-2"
                      v-tooltip.left="t('common.edit')"
                      light
                      circle
                      @click.prevent="onWorkRateEdit"
                    >
                      <FontIcon :name="editable ? 'pencil' : 'eye'" />
                    </AppButton>
                  </div>
                </div>
              </div>
              <!-- System role -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="system_role">{{ t('user.attributes.system_role') }}</FormLabel>
                </div>
                <div class="col">
                  <div class="form-wrapper">
                    <input
                      class="form-control"
                      :value="t(`user.system_role.${user.system_role ?? 'employee'}`)"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <!-- Firm admin -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="status">{{ t('user.attributes.firm_admin') }}</FormLabel>
                </div>
                <div class="col">
                  <input
                    type="checkbox"
                    class="form-check"
                    :checked="user.role === UserRole.CustomerAdmin || user.role === UserRole.SuperAdmin"
                    disabled
                  />
                  <label class="form-label" v-text="'&nbsp;'" />
                </div>
              </div>
              <!-- Status -->
              <div class="row align-items-center form-group">
                <div class="col-4">
                  <FormLabel class="mb-0" html-for="status">{{ t('user.attributes.status') }}</FormLabel>
                </div>
                <div class="col">
                  <UserStatus :status="user.status" />
                  <div class="d-none form-wrapper">
                    <input class="form-control" :value="t(`user.status.${user.status}`)" disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
      <div class="mt-3 d-flex">
        <AppButton class="mr-2" light @click.prevent="router.push({ name: 'users.index' })">
          {{ t('common.back') }}
        </AppButton>
        <AppButton
          class="ml-auto mr-2"
          light
          :href="route('system-redirects.admin.edit-user', { user: user.uuid })"
          target="_blank"
          link
        >
          {{ t('user.edit.edit_in_admin') }}
        </AppButton>
        <AppButton color="success" :disabled="disabled" :loading="submitLoader.isLoading.value">
          {{ t('common.update') }}
        </AppButton>
      </div>
    </form>
  </div>
</template>
