<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { ReportedTimeInfoRow, TimeSheetTable } from '@/components';
import useTime from '@/composables/useTime';
import useHelpers from '@/composables/useHelpers';
import { ITimeSheetPreviewResource, ITimeSheetResource } from '@/types/TimeSheet';

const { timeSheet, timeSheets } = defineProps<{
  timeSheet: ITimeSheetResource;
  timeSheets: ITimeSheetPreviewResource[];
}>();

const timeSheetId = defineModel<number>('id', { required: true });

const { t, locale } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();
const { calculatePercentages } = useHelpers();

const percentageTimes = computed(() => {
  const result = { client_time: 0, internal_time: 0, absence_time: 0 };
  const percentages = calculatePercentages(timeSheet.details.reported_time, [
    timeSheet.details.client_time,
    timeSheet.details.internal_time,
    timeSheet.details.absence_time,
  ]);
  result.client_time = percentages[0];
  result.internal_time = percentages[1];
  result.absence_time = percentages[2];
  return result;
});
</script>

<template>
  <div class="row">
    <div class="col-md">
      <h2>{{ t('time-sheet.review.general_info') }}</h2>
      <ReportedTimeInfoRow :title="t('time-sheet.review.attributes.month')" text="">
        <template #text>
          <div class="form-wrapper">
            <select class="form-control" v-model="timeSheetId">
              <option v-for="option in timeSheets" :key="option.id" :value="option.id">
                {{
                  DateTime.fromObject({
                    day: 1,
                    month: option.time_period.month,
                    year: option.time_period.year,
                  })
                    .setLocale(locale)
                    .toFormat('MMM yyyy')
                }}
              </option>
            </select>
          </div>
        </template>
      </ReportedTimeInfoRow>
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.time_sheet')"
        :text="t(`time-sheet.time_report_status.${timeSheet.is_completed ? 'completed' : 'not_completed'}`)"
      />
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.status')"
        :text="t(`time-sheet.status.${timeSheet.status}`)"
      />
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.review_status')"
        :text="t(`time-sheet.review_status.${timeSheet.review_status ?? 'not_reviewed'}`)"
      />
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.scheduled_time_for_the_month')"
        :text="convertMinutesToTime(timeSheet.details.scheduled_time)"
      />
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.overtime_for_the_month')"
        :text="timeSheet.details.overtime"
      >
        <template #text="{ text }">
          <span v-if="typeof text === 'number'" :class="{ 'text-danger-500': text < 0 }">
            <span v-if="text < 0">-</span>{{ convertMinutesToTime(Math.abs(text)) }}
          </span>
        </template>
      </ReportedTimeInfoRow>
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.flex_time')"
        :text="convertMinutesToTime(timeSheet.details.flex_time)"
      />
    </div>
    <div class="col-md">
      <h2>{{ t('time-sheet.review.reported_hours') }}</h2>
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.total_time')"
        :text="convertMinutesToTime(timeSheet.details.reported_time)"
        extra="&nbsp;"
      >
        <template #text="{ text }">
          <strong>{{ text }}</strong>
        </template>
      </ReportedTimeInfoRow>
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.client_time')"
        :text="convertMinutesToTime(timeSheet.details.client_time)"
        :extra="`${percentageTimes.client_time}%`"
      />
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.internal_time')"
        :text="convertMinutesToTime(timeSheet.details.internal_time)"
        :extra="`${percentageTimes.internal_time}%`"
      />
      <ReportedTimeInfoRow
        :title="t('time-sheet.review.attributes.absence')"
        :text="convertMinutesToTime(timeSheet.details.absence_time)"
        :extra="`${percentageTimes.absence_time}%`"
      />
    </div>
  </div>
  <h2 class="mt-5">{{ t('time-sheet.review.weekly_reported') }}</h2>
  <TimeSheetTable
    class="mt-3"
    v-for="reportedWeek in timeSheet.details.weeks"
    :key="reportedWeek.week"
    :week="reportedWeek.week.toString()"
    :data="reportedWeek.time_sheet"
    :expanded="true"
    :collapsable="false"
    :editable="false"
    :creatable="false"
    :month="timeSheet.time_period.month"
  />
</template>
