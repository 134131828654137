import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { debounce } from 'perfect-debounce';

import { IServicePreviewResource } from '@/types/Service';
import api from '@/services/api';

export default function useServices() {
  const { t } = useI18n({ useScope: 'global' });

  const priceOptions = computed(() => [
    { value: 'fixed', label: t('service.price_type.fixed') },
    { value: 'hourly', label: t('service.price_type.hourly') },
  ]);

  const invoiceFrequencyOptions = computed(() => [
    //{ value: null, label: t('service.invoice_frequency.once') },
    { value: 'monthly', label: t('service.invoice_frequency.monthly') },
    { value: 'quarterly', label: t('service.invoice_frequency.quarterly') },
    { value: 'semi-annually', label: t('service.invoice_frequency.semi-annually') },
    { value: 'annually', label: t('service.invoice_frequency.annually') },
  ]);

  const priceDeadlineOptions = computed(() => [
    { value: 'op1', label: t('service.price_deadline.op1') },
    { value: 'op2', label: t('service.price_deadline.op2') },
    { value: 'op3', label: t('service.price_deadline.op3') },
    { value: 'op4', label: t('service.price_deadline.op4') },
  ]);

  const services = ref<IServicePreviewResource[]>([]);

  async function fetchServices(opts?: {
    search?: string;
    withoutPagination?: boolean;
    ids?: string[];
    onlyActive?: boolean;
  }): Promise<void> {
    try {
      const searchParams = new URLSearchParams();
      if (opts?.search) {
        searchParams.append('search', opts.search);
      }
      if (opts?.withoutPagination) {
        searchParams.append('without_pagination', '1');
      }
      if (opts?.onlyActive) {
        searchParams.append('only_active', '1');
      }
      if (opts?.ids) {
        opts.ids.forEach((id) => searchParams.append('ids[]', id));
      }
      const response = await api.services.list({ searchParams });
      services.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const onSearchServices = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    if (search.length <= 1) return;
    loading(true);
    await fetchServices({ search, withoutPagination: true });
    loading(false);
  }, 300);

  return {
    priceOptions,
    invoiceFrequencyOptions,
    priceDeadlineOptions,
    services,
    fetchServices,
    onSearchServices,
  };
}
