<script setup lang="ts">
import { ref, onMounted, watch, reactive, computed } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';

import api from '@/services/api';
import {
  AppAlert,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FormLabel,
  FormInput,
  AppPagination,
  HelpInformation,
  AppLink,
  AppCollapse,
  FontIcon,
} from '@/components';
import useLoader from '@/composables/useLoader';
import { IUserListResource } from '@/types/User';
import { IOfficeListResource } from '@/types/Office';
import { IDepartmentResource } from '@/types/Department';
import { IWorkTitleResource } from '@/types/WorkTitle';
import useAuthStore from '@/store/AuthStore';
import { useTitle } from '@vueuse/core';

const users = ref<IUserListResource[]>([]);
const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);

type Filters = {
  loading: boolean;
  name: string;
  offices: number[];
  departments: number[];
  work_titles: number[];
};
const filters = reactive<Filters>({
  loading: false,
  name: '',
  offices: [],
  departments: [],
  work_titles: [],
});

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { authenticatedUser, isManagerRole } = useAuthStore();

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.name) searchParams.append('name', filters.name);
    filters.offices.forEach((id) => searchParams.append('offices[]', id.toString()));
    filters.departments.forEach((id) => searchParams.append('departments[]', id.toString()));
    filters.work_titles.forEach((id) => searchParams.append('work_titles[]', id.toString()));
    const response = await api.users.list({ searchParams });
    users.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  filters.loading = true;
  currentPage.value = 1;
  await getUsers();
  filters.loading = false;
}

async function resetFilters() {
  filters.name = '';
  filters.offices = [];
  filters.departments = [];
  filters.work_titles = [];
  await onFilter();
}

const offices = ref<IOfficeListResource[]>([]);
const officesLoading = ref(false);

const departments = ref<IDepartmentResource[]>([]);
const departmentsLoading = ref(false);

const workTitles = ref<IWorkTitleResource[]>([]);
const workTitlesLoading = ref(false);

async function getOffices() {
  try {
    officesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.offices.list({ searchParams });
    offices.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    officesLoading.value = false;
  }
}

async function getDepartments() {
  try {
    departmentsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.departments.list({ searchParams });
    departments.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    departmentsLoading.value = false;
  }
}

async function getWorkTitles() {
  try {
    workTitlesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.workTitles.list({ searchParams });
    workTitles.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    workTitlesLoading.value = false;
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getUsers(), getOffices(), getDepartments(), getWorkTitles()]);
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getUsers();
  loader.finish();
});

const title = useTitle(computed(() => t('coworkers.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="coworkers.index.help" />
    </div>
    <AppCollapse :opened="true" class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          <!-- Name -->
          <div class="form-group col">
            <FormLabel html-for="filter_name">
              {{ t('user.index.filters.name') }}
            </FormLabel>
            <FormInput
              type="search"
              v-model.trim="filters.name"
              id="filter_name"
              icon="search"
              :disabled="filters.loading"
            />
          </div>
          <!-- Office -->
          <div class="form-group col">
            <FormLabel html-for="filter_office">
              {{ t('user.attributes.office') }}
            </FormLabel>
            <VueSelect
              v-model="filters.offices"
              :options="offices"
              :reduce="(option: IOfficeListResource) => option.id"
              label="name"
              input-id="filter_office"
              :placeholder="t('common.select')"
              :disabled="officesLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Department -->
          <div class="form-group col">
            <FormLabel html-for="filter_department">
              {{ t('user.attributes.department') }}
            </FormLabel>
            <VueSelect
              v-model="filters.departments"
              :options="departments"
              :reduce="(option: IDepartmentResource) => option.id"
              label="name"
              input-id="filter_department"
              :placeholder="t('common.select')"
              :disabled="departmentsLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <!-- Work title -->
          <div class="form-group col">
            <FormLabel html-for="filter_work_title">
              {{ t('user.attributes.work_title') }}
            </FormLabel>
            <VueSelect
              v-model="filters.work_titles"
              :options="workTitles"
              :reduce="(option: IWorkTitleResource) => option.id"
              label="name"
              input-id="filter_work_title"
              :placeholder="t('common.select')"
              :disabled="workTitlesLoading"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="filters.loading">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filters.loading">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="users.length === 0">
        {{ t('user.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('user.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.office') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.department') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.work_title') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.email') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.phone_number') }}</AppTableTh>
              <AppTableTh class="text-right" nowrap>{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="user in users" :key="user.uuid">
              <AppTableTd nowrap>
                <strong v-text="user.name" />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="user.office" v-text="user.office.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="user.department" v-text="user.department.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="user.work_title" v-text="user.work_title.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <AppLink :value="user.email" mode="email" />
              </AppTableTd>
              <AppTableTd nowrap>
                <AppLink v-if="user.phone_number" :value="user.phone_number" mode="phone" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <RouterLink
                  v-if="authenticatedUser.uuid === user.manager?.uuid && isManagerRole"
                  custom
                  :to="{ name: 'overview', params: { uuid: user.uuid } }"
                  v-slot="{ href, navigate }"
                >
                  <a @click.stop="navigate" :href="href" target="_blank">
                    <AppButton size="small" light circle v-tooltip.left="t('coworkers.tooltip.overview')">
                      <FontIcon name="dashboard" />
                    </AppButton>
                  </a>
                </RouterLink>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
