import route from 'ziggy-js';
import { Options, SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import {
  ClientType,
  IClientCategory,
  IClientFullResource,
  IClientRequestBody,
  IClientSearchResource,
  IClientBasicInfo,
  IClientContactFullResource,
  IClientContactRequestBody,
  IClientContactRole,
  IClientStoreRequestBody,
  IClientUpdateRequestBody,
  IClientUpdateInformationRequestBody,
  IClientCreditInfo,
  IClientBillingListResource,
  IClientProcesses,
  IClientTagResource,
  IClientTagRequest,
  IClientItSystemResource,
  IClientItSystemRequest,
  IClientListResource,
  IClientCheckpointListResource,
} from '@/types/Client';
import { IResponse } from '@/types/Response';
import {
  IProjectCheckpointEventRequest,
  IProjectCheckpointEventResource,
  IProjectPreviewResource,
  IProjectTaskListResource,
} from '@/types/Project';
import {
  IEventReportedTimeResource,
  ITimeEntryProjectTaskEventRequest,
  ITimeEntryProjectTaskEventResource,
} from '@/types/Event';
import { IContactRoleRequestBody } from '@/types/Contact';

type GetClientMeta = {
  meta: {
    is_user_part_of_team: boolean;
  };
};

export default {
  async list(options?: Options): Promise<IResponse<IClientListResource[]>> {
    return await httpClient.get(route('clients.index'), options).json();
  },
  async get(client: string): Promise<IResponse<IClientFullResource> & GetClientMeta> {
    return await httpClient.get(route('clients.get', { client })).json();
  },
  async store(body: IClientStoreRequestBody): Promise<IResponse<IClientFullResource>> {
    return await httpClient.post(route('clients.store'), { json: body }).json();
  },
  async update(
    body: IClientUpdateRequestBody | IClientUpdateInformationRequestBody,
    client: string,
  ): Promise<IResponse<IClientFullResource>> {
    return await httpClient.patch(route('clients.update', { client }), { json: body }).json();
  },
  async destroy(client: string): Promise<IResponse<never>> {
    return await httpClient.delete(route('clients.delete', { client })).json();
  },
  async search(search: string, type: ClientType): Promise<IResponse<IClientSearchResource[]>> {
    return await httpClient.get(route('client'), { searchParams: { type, search } }).json();
  },
  async getClientCreditReportDownloadLink(client: string): Promise<IResponse<{ url: string }>> {
    return await httpClient.get(route('clients.client-credit-report-download-link', { client })).json();
  },
  contacts: {
    async list(client: string, searchParams?: SearchParamsOption): Promise<IResponse<IClientContactFullResource[]>> {
      return await httpClient.get(route('clients.contacts.list', { client }), { searchParams }).json();
    },
    async sync(client: string): Promise<IResponse<IClientContactFullResource[]>> {
      return await httpClient.get(route('clients.contacts.sync', { client }), {}).json();
    },
    async store(client: string, body: IClientContactRequestBody): Promise<IResponse<IClientContactFullResource>> {
      return await httpClient.post(route('clients.contacts.store', { client }), { json: body }).json();
    },
    async update(
      client: string,
      contact: string,
      body: IClientContactRequestBody,
    ): Promise<IResponse<IClientContactFullResource>> {
      return await httpClient.patch(route('clients.contacts.update', { client, contact }), { json: body }).json();
    },
    roles: {
      async list(searchParams?: SearchParamsOption): Promise<IResponse<IClientContactRole[]>> {
        return await httpClient.get(route('clients.contacts.roles.index'), { searchParams }).json();
      },
      async store(body: IContactRoleRequestBody): Promise<IResponse<IClientContactRole>> {
        return await httpClient.post(route('clients.contacts.roles.store'), { json: body }).json();
      },
    },
  },
  projects: {
    async list(client: string, options: Options = {}): Promise<IResponse<IProjectPreviewResource[]>> {
      return await httpClient.get(route('clients.projects.index', { client }), options).json();
    },
    async get(client: string, id: number): Promise<IResponse<IClientFullResource>> {
      return await httpClient.get(route('clients.projects.get', { client, id })).json();
    },
    async store(body: IClientRequestBody): Promise<IResponse<IClientFullResource>> {
      return await httpClient.post(route('clients.projects.store'), { json: body }).json();
    },
    async update(body: IClientRequestBody, client: string, id: number): Promise<IResponse<IClientFullResource>> {
      return await httpClient.patch(route('clients.projects.update', { client, id }), { json: body }).json();
    },
    async destroy(client: string, id: number): Promise<IResponse<never>> {
      return await httpClient.delete(route('clients.projects.delete', { client, id })).json();
    },
    tasks: {
      async list(client: string, searchParams?: SearchParamsOption): Promise<IResponse<IProjectTaskListResource[]>> {
        return await httpClient.get(route('clients.tasks-list', { client }), { searchParams }).json();
      },
      store(client: string, id: number, serviceTask: number, json: ITimeEntryProjectTaskEventRequest) {
        return httpClient
          .post(route('clients.projects.tasks.store', { client, id, serviceTask }), { json })
          .json<IResponse<ITimeEntryProjectTaskEventResource>>();
      },
    },
    checkpoints: {
      events: {
        index(options: Options = {}) {
          return httpClient
            .get(route('clients.projects.checkpoints.events.index'), options)
            .json<IResponse<IClientCheckpointListResource[]>>();
        },
        update(event: number, json: IProjectCheckpointEventRequest, options: Options = {}) {
          return httpClient
            .patch(route('clients.projects.checkpoints.events.update', { event }), { ...options, json })
            .json<IResponse<IProjectCheckpointEventResource>>();
        },
      },
    },
  },
  info: {
    async basic(org_number: string, type: ClientType): Promise<IClientBasicInfo> {
      return await httpClient
        .get(route('clients.client_info.basic-client-info'), { searchParams: { org_number, type } })
        .json();
    },
    async credit(client: string): Promise<IResponse<IClientCreditInfo>> {
      return await httpClient.get(route('clients.client_info.credit-client-info', { client }), {}).json();
    },
  },
  processes: {
    async get(client: string): Promise<IResponse<IClientProcesses>> {
      return await httpClient.get(route('clients.processes.get', { client })).json();
    },
    async update(client: string, json: IClientProcesses): Promise<IResponse<IClientProcesses>> {
      return await httpClient.put(route('clients.processes.update', { client }), { json }).json();
    },
  },
  events: {
    async index(client: string, options?: Options): Promise<IEventReportedTimeResource> {
      return await httpClient.get(route('clients.events-list', { client }), options).json();
    },
  },
  categories: {
    async list(): Promise<IResponse<IClientCategory[]>> {
      return await httpClient.get(route('clients.client-categories')).json();
    },
  },
  billing: {
    async list(client: string, searchParams?: SearchParamsOption): Promise<IResponse<IClientBillingListResource>> {
      return await httpClient.get(route('clients.billing.index', { client }), { searchParams }).json();
    },
  },
  serviceCompanies: {
    async attach(client: string, serviceCompany: string): Promise<never> {
      return await httpClient.post(route('clients.service-companies.attach', { client, serviceCompany })).json();
    },
    async detach(client: string, serviceCompany: string): Promise<never> {
      return await httpClient.post(route('clients.service-companies.detach', { client, serviceCompany })).json();
    },
    contacts: {
      async attach(client: string, serviceCompany: string, serviceCompanyContact: string): Promise<never> {
        return await httpClient
          .post(route('clients.service-companies.contacts.attach', { client, serviceCompany, serviceCompanyContact }))
          .json();
      },
      async detach(client: string, serviceCompany: string, serviceCompanyContact: string): Promise<never> {
        return await httpClient
          .post(route('clients.service-companies.contacts.detach', { client, serviceCompany, serviceCompanyContact }))
          .json();
      },
    },
  },
  tags: {
    async index(options: Options = {}): Promise<IResponse<IClientTagResource[]>> {
      return await httpClient.get(route('clients.tags.index'), options).json();
    },
    async store(json: IClientTagRequest, options: Options = {}): Promise<IResponse<IClientTagResource>> {
      return await httpClient.post(route('clients.tags.store'), { ...options, json }).json();
    },
    async update(tag: number, json: IClientTagRequest, options: Options = {}): Promise<IResponse<IClientTagResource>> {
      return await httpClient.patch(route('clients.tags.update', { tag }), { ...options, json }).json();
    },
    async delete(tag: number, options: Options = {}): Promise<void> {
      return await httpClient.delete(route('clients.tags.delete', { tag }), options).json();
    },
  },
  itSystems: {
    async index(options: Options = {}): Promise<IResponse<IClientItSystemResource[]>> {
      return await httpClient.get(route('clients.it-systems.index'), options).json();
    },
    async store(json: IClientItSystemRequest, options: Options = {}): Promise<IResponse<IClientItSystemResource>> {
      return await httpClient.post(route('clients.it-systems.store'), { ...options, json }).json();
    },
    async update(
      system: number,
      json: IClientItSystemRequest,
      options: Options = {},
    ): Promise<IResponse<IClientItSystemResource>> {
      return await httpClient.patch(route('clients.it-systems.update', { system }), { ...options, json }).json();
    },
    async delete(system: number, options: Options = {}): Promise<void> {
      return await httpClient.delete(route('clients.it-systems.delete', { system }), options).json();
    },
  },
};
