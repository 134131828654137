<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { DateTime } from 'luxon';

import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  FontIcon,
  TimeEntryDropdownOptions,
} from '@/components';
import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';
import { EventType, IWeeklyReportedTimeResource } from '@/types/Event';
import { ActivityModalProps } from '@/types/Activity';
import { computed } from 'vue';

type Props = {
  expanded: boolean;
  week: string;
  data: IWeeklyReportedTimeResource;
  collapsable?: boolean;
  editable?: boolean;
  creatable?: boolean;
  month?: number;
  showAllTasks?: boolean;
};

const {
  expanded,
  week,
  data,
  collapsable = true,
  editable = true,
  creatable = true,
  showAllTasks = true,
} = defineProps<Props>();

const emit = defineEmits<{
  (e: 'toggle', week: string): void;
  (e: 'activityCreate', params: ActivityModalProps): void;
  (e: 'projectTaskCreate', params: { initialWeek?: number }): void;
  (e: 'activityEdit', id: number): void;
  (e: 'projectTaskEdit', id: number): void;
}>();

const events = computed(() => {
  if (showAllTasks) {
    return data.events;
  }
  return data.events.filter((event) => event.total > 0);
});

const { t, d } = useI18n({ useScope: 'global' });
const { convertMinutesToTime, convertMinutesToShortTime } = useTime();
const { formatYearWeek } = useDate();
</script>

<template>
  <AppBox>
    <AppBoxBody class="p-0">
      <AppTable class="table-timesheet">
        <AppTableHead>
          <AppTableTr class="table-head-row">
            <AppTableTh class="pr-0" style="width: 36%" nowrap>
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0 text-neutral-900">
                  {{ t('dashboard.timesheet.timesheet') }} {{ formatYearWeek(week) }}
                </h4>
                <AppButton
                  v-if="collapsable"
                  class="mr-1"
                  size="small"
                  @click.prevent="emit('toggle', week)"
                  light
                  circle
                >
                  <FontIcon v-if="expanded" name="chevron-up" />
                  <FontIcon v-else name="chevron-down" />
                </AppButton>
              </div>
            </AppTableTh>
            <AppTableTh
              class="text-center"
              style="width: 8%"
              v-for="(summary, day) in data.summary.days"
              :key="day"
              nowrap
              :class="{ 'background-neutral-300': month && DateTime.fromISO(day as string).month !== month }"
            >
              <span class="text-6">
                <span v-text="d(new Date(day), 'weekday')" />
                <span
                  :class="{ 'text-danger-500': data.working_hours.days[day].is_holiday }"
                  v-if="data.working_hours.days[day] && [1,2,3,4,5].includes(DateTime.fromISO(day as string).weekday)"
                >
                  ({{ convertMinutesToShortTime(data.working_hours.days[day].time) }})
                </span>
              </span>
            </AppTableTh>
            <AppTableTh nowrap class="text-center" style="width: 8%">
              <Dropdown v-if="creatable" placement="left" :distance="0">
                <AppButton color="secondary" light size="small" circle>
                  <FontIcon name="calendar-exclamation" />
                </AppButton>
                <template #popper="{ hide }">
                  <TimeEntryDropdownOptions
                    @on-absence="emit('activityCreate', $event)"
                    @on-project-task="emit('projectTaskCreate', $event)"
                    @on-internal="emit('activityCreate', $event)"
                    :hide="hide"
                    :initial-week="Number(week)"
                  />
                </template>
              </Dropdown>
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <template v-if="expanded">
            <AppTableTr v-for="event in events" :key="event.id">
              <AppTableTd>
                <div class="d-flex align-items-center"></div>
                <template v-if="event.type === EventType.Activity">
                  <span class="badge is-primary" v-text="t('event.type.activity')" />
                  <strong class="text-primary-900 pl-1">
                    <span class="pointer" v-if="editable" @click.prevent="emit('activityEdit', event.id)">
                      {{ event.name }}
                    </span>
                    <span v-else>{{ event.name }}</span>
                  </strong>
                </template>
                <template v-else-if="event.type === EventType.ProjectTask">
                  <span class="badge is-secondary" v-text="t('event.type.project_task')" />
                  <strong class="text-secondary-900 pl-1">
                    <span class="pointer" v-if="editable" @click.prevent="emit('projectTaskEdit', event.id)">
                      {{ event.name }}
                    </span>
                    <span v-else>{{ event.name }}</span>
                  </strong>
                </template>
              </AppTableTd>
              <AppTableTd class="text-center" v-for="(times, eventDay) in event.days" :key="eventDay">
                <div v-for="(item, idx) in times" :key="idx">
                  {{ convertMinutesToTime(item.time) }}
                  <FontIcon
                    class="text-neutral-500"
                    v-if="item.note"
                    v-tooltip.bottom="item.note"
                    name="info-circle-filled"
                  />
                </div>
              </AppTableTd>
              <AppTableTd class="text-center font-bold">
                {{ convertMinutesToTime(event.total) }}
              </AppTableTd>
            </AppTableTr>
          </template>
          <AppTableTr>
            <AppTableTd>
              <strong>{{ t('dashboard.timesheet.summary') }}</strong>
            </AppTableTd>
            <AppTableTd class="text-center font-bold" v-for="(summary, day) in data.summary.days" :key="day">
              {{ convertMinutesToTime(summary) }}
            </AppTableTd>
            <AppTableTd class="text-center font-bold">
              {{ convertMinutesToTime(data.summary.total) }}
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </AppBoxBody>
  </AppBox>
</template>

<style scoped lang="scss">
.table-head-row {
  background-color: var(--color-neutral-100-hex);
}

.table-timesheet {
  td,
  th {
    border: 1px solid var(--color-neutral-200-hex);
  }
  tr {
    th,
    td {
      &:first-child {
        padding-left: var(--box-padding-base);
      }
    }
    th,
    td {
      &:last-child {
        padding-right: var(--box-padding-base);
      }
    }
  }
}
</style>
