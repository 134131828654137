<script setup lang="ts">
import { ref, computed, onMounted, reactive, watch, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { useConfirmDialog, useTitle } from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';

import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import {
  IServiceCompanyBodyRequest,
  IServiceCompanyContactPreviewResource,
  IServiceCompanyContactRequestBody,
  IServiceCompanyContactRoleResource,
} from '@/types/ServiceCompany';
import {
  AppAlert,
  AppLoader,
  AppButton,
  FontIcon,
  AppBox,
  AppBoxBody,
  FormLabel,
  FormInput,
  FormTextarea,
  AppTable,
  AppTableTd,
  AppTableTr,
  AppTableBody,
  AppTableHead,
  AppTableTh,
  ConfirmModal,
  ServiceCompanyContactModal,
  LeaveConfirmModal,
  AppPagination,
  HelpInformation,
} from '@/components';
import { IUserPreviewResource } from '@/types/User';

const props = defineProps<{
  uuid?: string;
}>();

const { isRevealed, reveal, confirm, cancel } = useConfirmDialog();

const loader = useLoader();
const submitLoader = useLoader();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

const users = ref<IUserPreviewResource[]>([]);

// Contacts data
const contactsElement = ref<HTMLDivElement | null>(null);
const contactsLoader = useLoader({ useProgress: false });
const currentContactsPage = useRouteQuery('contact-page', '1', { transform: Number });
const contactsPerPage = ref(20);
const contactsTotal = ref(0);
const serviceCompanyContacts = ref<IServiceCompanyContactPreviewResource[]>([]);
const serviceCompanyContactRoles = ref<IServiceCompanyContactRoleResource[]>([]);

const originContactUuid = ref('');

const originServiceCompany = ref('');
const isFormChanged = computed(() => JSON.stringify(form) !== originServiceCompany.value);

const form = reactive<IServiceCompanyBodyRequest>({
  name: '',
  website: '',
  phone: '',
  email: '',
  partner_discount: '',
  general_info: '',
  systems_descriptions: '',
  responsible_user_uuid: '',
  contact_uuid: '',
});

const editMode = computed(() => !!props.uuid);
const title = computed(() => (editMode.value ? t('service-company.edit.title') : t('service-company.create.title')));

async function getServiceCompany() {
  try {
    const response = await api.serviceCompanies.get(props.uuid!);
    form.name = response.data.name;
    form.website = response.data.website ?? '';
    form.phone = response.data.phone ?? '';
    form.email = response.data.email ?? '';
    form.partner_discount = response.data.partner_discount ?? '';
    form.general_info = response.data.general_info ?? '';
    form.systems_descriptions = response.data.systems_descriptions ?? '';
    form.responsible_user_uuid = response.data.responsible_user?.uuid ?? '';
    form.contact_uuid = originContactUuid.value = response.data.main_contact?.uuid ?? '';
    contactModal.patchOptions({ attrs: { roles: serviceCompanyContactRoles.value } });
    originServiceCompany.value = JSON.stringify(form);
  } catch (error) {
    console.error(error);
  }
}

async function getServiceCompanyContacts() {
  try {
    contactsLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentContactsPage.value.toString());
    const response = await api.serviceCompanies.contacts.list(props.uuid!, searchParams);
    serviceCompanyContacts.value = response.data;
    contactsPerPage.value = response.meta!.per_page;
    contactsTotal.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    contactsLoader.finish();
  }
}

async function getServiceContactRoles() {
  try {
    const searchParams = new URLSearchParams();
    const response = await api.serviceCompanies.roles.list(searchParams);
    serviceCompanyContactRoles.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('status', 'active');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function submit() {
  try {
    submitLoader.start();
    if (editMode.value) {
      const response = await api.serviceCompanies.update(props.uuid!, form);
      originContactUuid.value = response.data.main_contact?.uuid ?? '';
      originServiceCompany.value = JSON.stringify(form);
    } else {
      const response = await api.serviceCompanies.store(form);
      await router.push({ name: 'service-organizations.edit', params: { uuid: response.data.uuid } });
    }
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

const contactModal = useModal({
  component: ServiceCompanyContactModal,
  attrs: {
    async onCreate(form: IServiceCompanyContactRequestBody) {
      try {
        contactModal.patchOptions({ attrs: { loading: true } });
        await api.serviceCompanies.contacts.store(props.uuid!, {
          ...form,
          personal_number: form.personal_number.replaceAll('-', ''),
        });
        await getServiceCompanyContacts();
        await contactModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        contactModal.patchOptions({ attrs: { loading: false } });
      }
    },
    async onUpdate(uuid: string, form: IServiceCompanyContactRequestBody) {
      try {
        contactModal.patchOptions({ attrs: { loading: true } });
        await api.serviceCompanies.contacts.update(props.uuid!, uuid, {
          ...form,
          personal_number: form.personal_number.replaceAll('-', ''),
        });
        await getServiceCompanyContacts();
        await contactModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        contactModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      contactModal.close();
    },
    onClosed() {
      contactModal.patchOptions({ attrs: { loading: false, title: '', contact: null } });
    },
  },
});

const deleteContactModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('service-company.contact.confirm.destroy.title'),
    async onConfirm() {
      try {
        deleteContactModal.patchOptions({ attrs: { loading: true } });
        const { uuid } = deleteContactModal.options.attrs!.params;
        if (form.contact_uuid === uuid) {
          form.contact_uuid = originContactUuid.value;
        }
        await api.serviceCompanies.contacts.destroy(props.uuid!, uuid);
        await getServiceCompanyContacts();
        if (serviceCompanyContacts.value.length === 0 && currentContactsPage.value > 1) {
          currentContactsPage.value -= 1;
        }
        await deleteContactModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        deleteContactModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      deleteContactModal.close();
    },
    onClosed() {
      deleteContactModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

function onCreateContact() {
  contactModal.patchOptions({ attrs: { title: t('service-company.contact.create.title') } });
  contactModal.open();
}

function onEditContact(contact: IServiceCompanyContactPreviewResource) {
  contactModal.patchOptions({
    attrs: {
      title: t('service-company.contact.edit.title', { name: contact.name }),
      contact,
    },
  });
  contactModal.open();
}

function onDeleteContact(contact: IServiceCompanyContactPreviewResource) {
  deleteContactModal.patchOptions({
    attrs: {
      params: { uuid: contact.uuid },
      message: t('service-company.contact.confirm.destroy.text', { name: contact.name }),
    },
  });
  deleteContactModal.open();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getServiceContactRoles(), getUsers()]);
  if (props.uuid) {
    await Promise.all([getServiceCompany(), getServiceCompanyContacts()]);
  }
  loader.finish();
});

// Prevent data loss: START
function onBeforeUnload(event: BeforeUnloadEvent) {
  if (!isFormChanged.value) return true;
  event.preventDefault();
  const customMessage = t('common.confirms.unsaved.title');
  event.returnValue = customMessage;
  return customMessage;
}

onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});

onBeforeRouteLeave(async (to, from, next) => {
  if (editMode.value && isFormChanged.value) {
    const { data } = await reveal();
    data ? next() : next(false);
  } else {
    next();
  }
});

// Prevent data loss: END

watch(currentContactsPage, async () => {
  await getServiceCompanyContacts();
  contactsElement.value!.scrollIntoView({ behavior: 'smooth', block: 'start' });
});

useTitle(computed(() => title.value));
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit" class="container-wide">
    <div class="d-flex align-items-center mb-4">
      <AppButton @click.prevent="router.back" color="secondary" circle light v-tooltip="t('common.back')">
        <FontIcon name="arrow-back-up" />
      </AppButton>
      <div class="d-flex align-items-end ml-3">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation
          class="ml-1"
          :translation="editMode ? 'service-company.edit.help' : 'service-company.create.help'"
        />
      </div>
    </div>
    <AppBox shadow>
      <AppBoxBody>
        <!-- General info -->
        <h2 v-text="t('service-company.form.general_info')" />
        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="name" required>{{ t('service-company.attributes.name') }}</FormLabel>
            <FormInput id="name" v-model="form.name" required />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="website">{{ t('service-company.attributes.website') }}</FormLabel>
            <FormInput icon="world-www" id="website" v-model="form.website" />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="phone">{{ t('service-company.attributes.phone') }}</FormLabel>
            <FormInput icon="device-mobile" id="phone" v-model="form.phone" />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="email">{{ t('service-company.attributes.email') }}</FormLabel>
            <FormInput icon="at" id="email" v-model="form.email" />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="partner_discount">{{ t('service-company.attributes.partner_discount') }}</FormLabel>
            <div class="form-wrapper has-icon">
              <i class="form-icon ti ti-percentage" />
              <input
                id="partner_discount"
                v-model="form.partner_discount"
                class="form-control"
                type="number"
                step="1"
                max="100"
                min="0"
              />
            </div>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="user">
              {{ t('service-company.attributes.responsible_user') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="form.responsible_user_uuid"
              :reduce="(option) => option.uuid"
              label="name"
              :options="users"
              input-id="user"
              :placeholder="t('common.not_selected')"
            />
          </div>
        </div>

        <!-- Contacts -->
        <div class="mt-5" ref="contactsElement" v-if="editMode">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h2 class="mb-0" v-text="t('service-company.form.contacts')" />
            <AppButton @click.prevent="onCreateContact" color="secondary" :disabled="contactsLoader.isLoading.value">
              {{ t('service-company.contact.tooltip.create') }}
            </AppButton>
          </div>
          <div shadow v-if="contactsLoader.isLoading.value" class="text-center">
            <AppLoader />
          </div>
          <template v-else>
            <AppAlert v-if="serviceCompanyContacts.length === 0">{{ t('service-company.contact.empty') }}</AppAlert>
            <template v-else>
              <AppTable hoverable>
                <AppTableHead>
                  <AppTableTr>
                    <AppTableTh class="pl-0" nowrap>{{ t('service-company.contact.attributes.name') }}</AppTableTh>
                    <AppTableTh class="pl-0" nowrap>
                      {{ t('service-company.contact.attributes.short_name') }}
                    </AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.role') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.phone') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.email') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.personal_number') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.main_contact') }}</AppTableTh>
                    <AppTableTh class="text-right pr-0" nowrap>
                      {{ t('service-company.contact.attributes.shared_clients') }}
                    </AppTableTh>
                    <AppTableTh class="text-right pr-0" nowrap>
                      {{ t('common.actions') }}
                    </AppTableTh>
                  </AppTableTr>
                </AppTableHead>
                <AppTableBody>
                  <AppTableTr v-for="contact in serviceCompanyContacts" :key="contact.uuid">
                    <AppTableTd class="pl-0"><strong v-text="contact.name" /></AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.short_name" v-text="contact.short_name" />
                      <i v-else class="text-neutral-300" v-text="t('common.empty')" />
                    </AppTableTd>
                    <AppTableTd>{{ contact.role.name }}</AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.phone" v-text="contact.phone" />
                      <i v-else class="text-neutral-300" v-text="t('common.empty')" />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.email" v-text="contact.email" />
                      <i v-else class="text-neutral-300" v-text="t('common.empty')" />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.personal_number" v-text="contact.personal_number" />
                      <i v-else class="text-neutral-300" v-text="t('common.empty')" />
                    </AppTableTd>
                    <AppTableTd>
                      <input type="radio" :value="contact.uuid" v-model="form.contact_uuid" class="pointer" />
                    </AppTableTd>
                    <AppTableTd class="text-right pr-0">
                      <i v-if="contact.shared_clients === 0" class="text-neutral-300">0</i>
                      <RouterLink
                        @click.stop
                        v-else
                        target="_blank"
                        :to="{
                          name: 'clients.index',
                          query: {
                            service_company: props.uuid,
                            service_company_contact: contact.uuid,
                            show_only_active: 0,
                          },
                        }"
                        class="pointer"
                      >
                        {{ contact.shared_clients }} ({{ t('service-company.contact.see_clients') }})
                      </RouterLink>
                    </AppTableTd>
                    <AppTableTd class="text-right pr-0" nowrap>
                      <AppButton
                        v-tooltip="t('service-company.contact.tooltip.edit', { name: contact.name })"
                        @click.stop.prevent="onEditContact(contact)"
                        size="small"
                        light
                        circle
                        class="ml-2"
                      >
                        <FontIcon name="pencil" />
                      </AppButton>
                      <AppButton
                        v-tooltip="t('service-company.contact.tooltip.destroy', { name: contact.name })"
                        @click.stop.prevent="onDeleteContact(contact)"
                        size="small"
                        light
                        circle
                        class="ml-2"
                        color="danger"
                        :disabled="originContactUuid === contact.uuid || contactsTotal === 1"
                      >
                        <FontIcon name="trash" />
                      </AppButton>
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>
              <AppPagination
                class="mt-3"
                :per-page="contactsPerPage"
                :total="contactsTotal"
                v-model="currentContactsPage"
              />
            </template>
          </template>
        </div>
        <!-- Other information -->
        <h2 class="mt-5" v-text="t('service-company.form.other_information')" />
        <div class="row">
          <div class="form-group col-lg-6">
            <FormLabel html-for="general_info">
              {{ t('service-company.attributes.general_info') }}
            </FormLabel>
            <FormTextarea rows="5" id="general_info" v-model="form.general_info" />
          </div>
          <div class="form-group col-lg-6">
            <FormLabel html-for="systems_descriptions">
              {{ t('service-company.attributes.systems_descriptions') }}
            </FormLabel>
            <FormTextarea rows="5" id="systems_descriptions" v-model="form.systems_descriptions" />
          </div>
        </div>
      </AppBoxBody>
      <AppBoxBody>
        <AppButton
          :color="editMode ? 'success' : 'secondary'"
          :loading="submitLoader.isLoading.value"
          :disabled="editMode && !isFormChanged"
        >
          {{ editMode ? t('common.update') : t('common.create') }}
        </AppButton>
      </AppBoxBody>
    </AppBox>
  </form>
  <LeaveConfirmModal :is-revealed="isRevealed" @confirm="confirm" @cancel="cancel" />
</template>
