<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueSelect from 'vue-select';
import { Decimal } from 'decimal.js';

import { AppButton, AppCloseButton, FormInput, FormLabel } from '@/components';
import api from '@/services/api';
import toast from '@/services/toast';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { ExpenseType, IExpenseRequest, IExpenseResource } from '@/types/Expense';
import { IProjectPreviewResource } from '@/types/Project';

type Props = {
  expense: null | IExpenseResource;
  projects: IProjectPreviewResource[];
  clientUuid: string;
};

const { expense, projects, clientUuid } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [expense: IExpenseResource];
  updated: [expense: IExpenseResource];
  closed: [];
}>();

const { t, locale } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const loading = ref(false);

const form = reactive<IExpenseRequest>({
  client_uuid: clientUuid,
  project_id: null,
  type: null,
  date: null,
  amount: null,
  description: null,
});

const tracker = useTrackChanges(form);

const disabled = computed(() => !(form.project_id && form.type && form.date && form.amount));

async function submit() {
  loading.value = true;
  try {
    if (expense) {
      const response = await api.expenses.update(expense.id, form);
      emit('updated', response.data);
      toast.success(
        t('common.messages.has_been_updated', {
          name: t('expense.resource.name'),
        }),
      );
    } else {
      const response = await api.expenses.create(form);
      emit('created', response.data);
      toast.success(
        t('common.messages.has_been_created', {
          name: t('expense.resource.name'),
        }),
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  if (expense) {
    form.type = expense.type;
    form.date = expense.date;
    form.amount = expense.amount;
    form.description = expense.description;
    form.project_id = expense?.project.id;
  }
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="expense ? t('expense.edit.title') : t('expense.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Type -->
        <div class="form-group row align-items-center">
          <div class="col-3">
            <FormLabel class="mb-0" html-for="type" required>
              {{ t('expense.attributes.type') }}
            </FormLabel>
          </div>
          <div class="col">
            <VueSelect
              :filterable="false"
              v-model="form.type"
              :get-option-label="(option: string) => t(`expense.type.${option}`)"
              :options="Object.values(ExpenseType)"
              input-id="type"
              :placeholder="t('common.select')"
              :clearable="false"
            />
          </div>
        </div>

        <!-- Project -->
        <div class="form-group row align-items-center">
          <div class="col-3">
            <FormLabel class="mb-0" html-for="project" required>
              {{ t('expense.attributes.project') }}
            </FormLabel>
          </div>
          <div class="col">
            <VueSelect
              v-model="form.project_id"
              :reduce="(option: IProjectPreviewResource) => option.id"
              :options="projects"
              label="name"
              input-id="project"
              :placeholder="t('common.select')"
              :clearable="false"
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
        </div>

        <!-- Date -->
        <div class="form-group row align-items-center">
          <div class="col-3">
            <FormLabel class="mb-0" html-for="dp-input-date" required>
              {{ t('free-day.attributes.date') }}
            </FormLabel>
          </div>
          <div class="col">
            <div class="form-wrapper has-icon">
              <VueDatePicker
                input-class-name="form-control"
                :placeholder="t('common.select')"
                v-model="form.date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                uid="date"
                teleport
                required
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
        </div>

        <!-- Amount -->
        <div class="form-group row align-items-center">
          <div class="col-3">
            <FormLabel class="mb-0" html-for="amount" required>
              {{ t('expense.attributes.amount') }}
            </FormLabel>
          </div>
          <div class="col">
            <div class="form-wrapper has-icon">
              <i class="form-icon ti ti-currency-krone-swedish" />
              <input
                id="amount"
                type="number"
                :value="form.amount"
                @change="({ target }: { target: HTMLInputElement}) => {
                  const value = new Decimal(target.value ? target.value : 0).toDecimalPlaces(2).abs();
                  form.amount = value.toNumber();
                  target.value = form.amount.toString();
                }"
                class="form-control"
                min="0"
                step=".01"
              />
            </div>
          </div>
        </div>

        <!-- Description -->
        <div class="form-group row align-items-center">
          <div class="col-3">
            <FormLabel class="mb-0" html-for="description">
              {{ t('expense.attributes.description') }}
            </FormLabel>
          </div>
          <div class="col">
            <FormInput id="description" v-model="form.description" />
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            v-if="expense"
            class="mt-2 mt-sm-0"
            color="success"
            :loading="loading"
            :disabled="disabled || !tracker.isModified.value"
          >
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
