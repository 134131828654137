export default {
  index: {
    title: 'My weekly planning',
    create: 'Create activity/task',
    task: 'Task & Notifications',
    week_one_letter: 'W',
    firm_activities: 'Firm activities',
    info: 'Info',
    hide_done: 'Hide done',
    show_all_tasks: 'Show all tasks',
  },
  buttons: {
    load_prev_week: 'Load previous week',
    load_next_week: 'Load next week',
  },
  timesheet: {
    timesheet: 'Timesheet',
    summary: 'Summary',
  },
};
