<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';

import api from '@/services/api';
import { AppAlert, AppButton, FinancialYearModal, FontIcon, FormInput, FormSwitch } from '@/components';
import { ICancelOrRolloverProjectOptions } from '@/types/Project';
import { IFinancialYearResource } from '@/types/FinancialYear';

const { t, locale } = useI18n({ useScope: 'global' });

const loading = ref(false);
const confirmButton = ref<HTMLButtonElement>();

type CallbackFunction = (newProjectId: number | null) => Promise<void>;
const props = defineProps<{
  type: 'cancel' | 'rollover';
  clientUuid: string;
  project: {
    id: number;
    name: string;
    has_unfinished_tasks: boolean;
  };
  onConfirmed: CallbackFunction;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

const financialYears = ref<IFinancialYearResource[]>([]);
const form = reactive<ICancelOrRolloverProjectOptions>({
  start_new_project: props.type === 'rollover',
  increase_prices: true,
  percentage_price_increase: 0,
  name: null,
  financial_year_uuid: null,
  start_date: DateTime.now().toFormat('yyyy-MM-dd'),
  end_date: null,
  deadline_date: null,
});

const financialYearModal = useModal({
  component: FinancialYearModal,
  attrs: {
    clientUuid: props.clientUuid,
    async onCreated(newFinancialYear: IFinancialYearResource) {
      await getFinancialYears(props.clientUuid);
      form.financial_year_uuid = newFinancialYear.uuid;
      onFiscalYearChange(newFinancialYear.uuid);
      await financialYearModal.close();
    },
    onCancel() {
      financialYearModal.close();
    },
  },
});

function onFiscalYearChange(uuid: string) {
  const fiscalYear = financialYears.value.find((f) => f.uuid === uuid);
  if (!fiscalYear) return;
  const date = DateTime.fromISO(fiscalYear.end_date).plus({ days: 182 }).toFormat('yyyy-MM-dd');
  form.deadline_date = date;
  form.end_date = date;
}

async function getFinancialYears(clientUuid: string) {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.financialYears.list(clientUuid, searchParams);
    financialYears.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function submit() {
  try {
    loading.value = true;
    const body = {
      ...form,
    };
    if (!body.start_new_project) {
      delete body.increase_prices;
      delete body.percentage_price_increase;
    }
    if (!body.increase_prices) {
      delete body.percentage_price_increase;
    }
    const { data } =
      props.type === 'cancel'
        ? await api.projects.cancel(props.clientUuid, props.project.id, body)
        : await api.projects.rollover(props.clientUuid, props.project.id, body);
    await props.onConfirmed(form.start_new_project ? data.id : null);
    emit('confirm');
  } catch (e) {
    //
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getFinancialYears(props.clientUuid);
});
</script>
<template>
  <teleport to="body">
    <div class="modal-overlay" role="dialog" style="z-index: 1000">
      <form @submit.prevent="submit" @keydown.enter.prevent>
        <div class="modal-container">
          <div class="modal-header">
            <h2
              v-text="
                props.type === 'cancel'
                  ? t('project.cancel_modal.title', { name: project.name })
                  : t('project.rollover_modal.title', { name: project.name })
              "
            />
          </div>
          <div class="modal-content">
            <p v-html="t('project.cancel_modal.message')" />
            <div class="row mt-4">
              <div class="col-10">{{ t('project.cancel_modal.start_new_project', { name: project.name }) }}</div>
              <div class="col-2 d-flex justify-content-end">
                <FormSwitch group-class="mb-0" id="start-new-project" v-model="form.start_new_project" />
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project">
              <div class="col-10">{{ t('project.cancel_modal.increase_prices') }}</div>
              <div class="col-2 d-flex justify-content-end">
                <FormSwitch group-class="mb-0" id="increase_prices" v-model="form.increase_prices" />
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project && form.increase_prices">
              <div class="col-10">{{ t('project.cancel_modal.percentage_price_increase') }}</div>
              <div class="col-2 d-flex justify-content-end">
                <FormInput
                  type="number"
                  v-model="form.percentage_price_increase"
                  id="percentage_price_increase"
                  icon="percentage"
                  required
                  :invalid="form.percentage_price_increase === 0"
                  step="0.01"
                />
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project">
              <div class="col-4">{{ t('project.attributes.financial_year') }}</div>
              <div class="col-8 d-flex align-items-center">
                <div class="flex-grow-1">
                  <VueSelect
                    :clearable="false"
                    :filterable="false"
                    v-model="form.financial_year_uuid"
                    :options="financialYears"
                    :reduce="(option: IFinancialYearResource) => option.uuid"
                    input-id="financial_year"
                    :placeholder="t('common.select')"
                    :get-option-label="
                        (option: IFinancialYearResource) => `${DateTime.fromISO(option.start_date).toFormat('yyyy-MM-dd')} - ${DateTime.fromISO(option.end_date).toFormat('yyyy-MM-dd')}`
                      "
                    required
                    @update:model-value="onFiscalYearChange"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.financial_year_uuid"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                </div>
                <AppButton @click.prevent="financialYearModal.open" class="flex-shrink-0 ml-2" light circle>
                  <FontIcon name="plus" />
                </AppButton>
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project">
              <div class="col-4">{{ t('project.attributes.start_date') }}</div>
              <div class="col-8">
                <div class="form-wrapper has-icon">
                  <VueDatePicker
                    uid="start_date"
                    input-class-name="form-control"
                    :placeholder="t('common.select')"
                    v-model="form.start_date"
                    model-type="format"
                    format="yyyy-MM-dd"
                    :enable-time-picker="false"
                    :month-change-on-scroll="false"
                    required
                    auto-apply
                    text-input
                    :max-date="form.end_date ?? ''"
                    :locale="locale"
                    :week-num-name="t('common.week_short')"
                    :clearable="false"
                    teleport
                  >
                    <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project">
              <div class="col-4">{{ t('project.attributes.end_date') }}</div>
              <div class="col-8">
                <div class="form-wrapper has-icon">
                  <VueDatePicker
                    uid="end_date"
                    input-class-name="form-control"
                    :placeholder="t('common.select')"
                    v-model="form.end_date"
                    model-type="format"
                    format="yyyy-MM-dd"
                    :enable-time-picker="false"
                    :month-change-on-scroll="false"
                    text-input
                    :min-date="form.start_date ?? ''"
                    required
                    auto-apply
                    :locale="locale"
                    :week-num-name="t('common.week_short')"
                    :clearable="false"
                    teleport
                  >
                    <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project">
              <div class="col-4">{{ t('project.attributes.deadline_date') }}</div>
              <div class="col-8">
                <div class="form-wrapper has-icon">
                  <VueDatePicker
                    uid="deadline_date"
                    input-class-name="form-control"
                    :placeholder="t('common.select')"
                    v-model="form.deadline_date"
                    model-type="format"
                    format="yyyy-MM-dd"
                    :enable-time-picker="false"
                    :month-change-on-scroll="false"
                    text-input
                    :min-date="form.start_date ?? ''"
                    :max-date="form.end_date ?? ''"
                    auto-apply
                    :locale="locale"
                    :week-num-name="t('common.week_short')"
                    teleport
                  >
                    <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                  </VueDatePicker>
                </div>
              </div>
            </div>
            <div class="row mt-4" v-if="form.start_new_project">
              <div class="col-4">{{ t('project.attributes.name') }}</div>
              <div class="col-8">
                <FormInput id="name" v-model="form.name" :placeholder="t('project.placeholders.name')" required />
              </div>
            </div>

            <AppAlert class="mt-4 p-3 text-5" v-if="project.has_unfinished_tasks" type="danger"
              >{{ t('project.cancel_modal.warning') }}
            </AppAlert>
          </div>
          <div class="modal-footer">
            <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
              <button class="button is-light" @click="emit('cancel')" :disabled="loading">
                {{ t('common.cancel') }}
              </button>
              <button
                ref="confirmButton"
                class="button is-success mt-2 mt-sm-0"
                :class="{ 'is-loading': loading }"
                :disabled="loading"
              >
                {{ t('common.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </teleport>
</template>

<style scoped>
.table tbody tr {
  border: none;
}
</style>
