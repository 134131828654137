<script setup lang="ts">
import { ref, computed, reactive, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';

import { IServicePreviewResource } from '@/types/Service';
import api from '@/services/api';
import {
  AppBox,
  AppBoxBody,
  AppAlert,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppPagination,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import { useTitle } from '@vueuse/core';

const services = ref<IServicePreviewResource[]>([]);
const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);
const filters = reactive({
  dirty: false,
  search: '',
});

const { isCustomerAdminRole, isSuperAdminRole } = useAuthStore();
const router = useRouter();
const { t, n, te } = useI18n({ useScope: 'global' });
const loader = useLoader();

const filterDisabled = computed(() => loader.isLoading.value);
const filterApplyDisabled = computed(() => filters.search === '');
const editable = computed(() => isSuperAdminRole || isCustomerAdminRole);

const deleteModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('service.confirm.destroy.title'),
    async onConfirm() {
      if (!deleteModal.options?.attrs?.params?.uuid) {
        return;
      }
      try {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: true } });
        await api.services.destroy(deleteModal.options.attrs.params.uuid as string);
        await getServices();
        if (services.value.length === 0 && currentPage.value > 1) {
          currentPage.value -= 1;
        }
        await deleteModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      deleteModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      deleteModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function getServices() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.search) searchParams.append('search', filters.search);
    const response = await api.services.list({ searchParams });
    services.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

function onServiceClick(service: IServicePreviewResource) {
  if (editable.value) {
    router.push({ name: 'services.edit', params: { uuid: service.uuid } });
  }
}

async function onDelete(service: IServicePreviewResource) {
  deleteModal.patchOptions({
    // @ts-ignore
    attrs: {
      params: { uuid: service.uuid },
      message: t('service.confirm.destroy.text', {
        name: service.name,
      }),
    },
  });
  await deleteModal.open();
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getServices();
  loader.finish();
  filters.dirty = true;
}

async function resetFilters() {
  filters.search = '';
  await onFilter();
  filters.dirty = false;
}

onMounted(async () => {
  loader.start();
  await getServices();
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getServices();
  loader.finish();
});

const title = useTitle(computed(() => t('service.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="service.index.help" />
      </div>
      <AppButton v-if="editable" @click.prevent="router.push({ name: 'services.create' })" color="secondary">
        {{ t('service.index.create') }}
      </AppButton>
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row d-flex align-items-end">
            <div class="col-3">
              <FormLabel html-for="filter_name">
                {{ t('service.index.filters.name') }}
              </FormLabel>
              <FormInput
                type="search"
                v-model.trim="filters.search"
                id="filter_name"
                icon="search"
                :disabled="filterDisabled"
              />
            </div>
            <div class="col-9">
              <AppButton :disabled="filterDisabled || filterApplyDisabled">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterDisabled || !filters.dirty">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="services.length === 0">
        {{ filters.dirty ? t('common.no_search_results') : t('service.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('service.attributes.name') }}</AppTableTh>
              <AppTableTh>{{ t('service.attributes.default_price_type') }}</AppTableTh>
              <AppTableTh>{{ t('service.attributes.default_invoice_frequency') }}</AppTableTh>
              <AppTableTh class="text-right">{{ t('service.attributes.default_fixed_price') }}</AppTableTh>
              <AppTableTh class="text-right">
                {{ t('service.attributes.default_hourly_price_adjustment') }}
              </AppTableTh>
              <AppTableTh nowrap>{{ t('service.attributes.default_dead_line') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('service.attributes.active') }}</AppTableTh>
              <AppTableTh v-if="editable" nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              @click="onServiceClick(service)"
              :class="{ pointer: editable }"
              v-for="service in services"
              :key="service.uuid"
            >
              <AppTableTd nowrap>
                <strong v-text="service.name" />
              </AppTableTd>
              <AppTableTd nowrap>
                {{ t(`service.price_type.${service.default_price_type}`) }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{ t(`service.invoice_frequency.${service.default_invoice_frequency || 'once'}`) }}
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                {{ n(service.default_fixed_price, 'currency') }}
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                {{ n(parseInt(service.default_hourly_price_adjustment), 'currency') }}
              </AppTableTd>
              <AppTableTd nowrap>
                <span
                  v-if="te(`service.price_deadline.${service.default_dead_line}`)"
                  v-text="t(`service.price_deadline.${service.default_dead_line}`)"
                />
                <span v-else v-text="service.default_dead_line" />
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <FontIcon v-if="service.active" class="text-2" name="check" />
              </AppTableTd>
              <AppTableTd v-if="editable" nowrap class="text-right">
                <AppButton
                  v-tooltip="t('service.tooltip.edit', { name: service.name })"
                  @click.stop="router.push({ name: 'services.edit', params: { uuid: service.uuid } })"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-if="!service.in_use"
                  v-tooltip="t('service.tooltip.destroy', { name: service.name })"
                  @click.stop="onDelete(service)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
