<script setup lang="ts">
import { ref, onMounted, computed, watch, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';

import api from '@/services/api';
import {
  AppBox,
  AppBoxBody,
  AppAlert,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormLabel,
  FormInput,
  AppPagination,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import { IUserListResource } from '@/types/User';
import authStore from '@/store/AuthStore';
import { useTitle } from '@vueuse/core';

const users = ref<IUserListResource[]>([]);
const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);
const filters = reactive({
  dirty: false,
  name: '',
});

const filterDisabled = computed(() => loader.isLoading.value);
const filterApplyDisabled = computed(() => filters.name === '');

const router = useRouter();
const { t, n } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isSuperAdminRole, isCustomerAdminRole } = authStore();

const editable = computed(() => isSuperAdminRole || isCustomerAdminRole);

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.name) searchParams.append('name', filters.name);
    const response = await api.users.list({ searchParams });
    users.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getUsers();
  loader.finish();
  filters.dirty = true;
}

async function resetFilters() {
  filters.name = '';
  await onFilter();
  filters.dirty = false;
}

onMounted(async () => {
  loader.start();
  await getUsers();
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getUsers();
  loader.finish();
});

const title = useTitle(computed(() => t('user.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="user.index.help" />
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row d-flex align-items-end">
            <div class="col-3">
              <FormLabel html-for="filter_name">
                {{ t('user.index.filters.name') }}
              </FormLabel>
              <FormInput
                type="search"
                v-model.trim="filters.name"
                id="filter_name"
                icon="search"
                :disabled="filterDisabled"
              />
            </div>
            <div class="col-9">
              <AppButton :disabled="filterDisabled || filterApplyDisabled">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterDisabled || !filters.dirty">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="users.length === 0">
        {{ filters.dirty ? t('common.no_search_results') : t('user.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('user.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.email') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.system_role') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.attributes.work_title') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('user.attributes.internal_price') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('user.attributes.work_rate') }}</AppTableTh>
              <AppTableTh v-if="editable" nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              @click="
                () => {
                  if (editable) {
                    router.push({ name: 'users.edit', params: { uuid: user.uuid } });
                  }
                }
              "
              :class="{ pointer: editable }"
              v-for="user in users"
              :key="user.uuid"
            >
              <AppTableTd nowrap>
                <strong v-text="user.name" />
                <AppButton
                  v-if="!user.meta"
                  class="ml-2"
                  v-tooltip="t('user.tooltip.additional_information_required')"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="exclamation-mark" />
                </AppButton>
              </AppTableTd>
              <AppTableTd nowrap>{{ user.email }}</AppTableTd>
              <AppTableTd nowrap>{{ t(`user.system_role.${user.system_role ?? 'employee'}`) }}</AppTableTd>
              <AppTableTd nowrap>
                <span v-if="user.work_title" v-text="user.work_title.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <span v-if="user.internal_cost" v-text="n(user.internal_cost, 'currency')" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap> {{ user.work_rate_percent }}% </AppTableTd>
              <AppTableTd v-if="editable" nowrap class="text-right">
                <AppButton
                  v-tooltip.left="t('user.tooltip.edit', { name: user.name })"
                  @click.stop="router.push({ name: 'users.edit', params: { uuid: user.uuid } })"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
