<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useRouteQuery } from '@vueuse/router';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import { useTitle } from '@vueuse/core';
import api from '@/services/api';
import {
  AppButton,
  AppLoader,
  FormLabel,
  AppPagination,
  HelpInformation,
  AppCollapse,
  InvoicesTable,
  GenerateInvoicesModal,
  CreateInvoiceModal,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useClients from '@/composables/useClients';
import { IInvoicePreviewResource, InvoiceExternalStatusType, InvoiceInternalStatusType } from '@/types/Invoice';
import { IUserListResource } from '@/types/User';
import { IClientPreviewResource } from '@/types/Client';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ITimePeriodResource } from '@/types/TimePeriod';

const currentPage = useRouteQuery('page', '1', { transform: Number });
const router = useRouter();
const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { clients, onSearchClients } = useClients();

const totalSum = ref(0);
const perPage = ref(20);
const total = ref(0);

const invoices = ref<IInvoicePreviewResource[]>([]);

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

// Filters
const filterLoader = useLoader();
const filterInternalStatuses = ref<InvoiceInternalStatusType[]>([]);
const filterExternalStatus = ref<string[]>([]);
const filterClientUuid = ref('');
const filterUserUuid = ref<string[]>([]);
const filterFromDate = ref('');
const filterToDate = ref('');
const filterOnlyMine = ref<number>(0);
const filterNotSynced = ref<number>(0);
const filterUnmatched = ref<number>(0);
const filterCreditedOrDeleted = ref<number>(0);

async function getInvoices() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filterClientUuid.value) searchParams.append('client_uuid[]', filterClientUuid.value);
    filterUserUuid.value.forEach((uuid) => searchParams.append('user_uuid[]', uuid));
    filterInternalStatuses.value.forEach((status) => searchParams.append('internal_status[]', status));
    filterExternalStatus.value.forEach((status) => searchParams.append('external_status[]', status));
    if (filterFromDate.value) searchParams.append('created_from_date', filterFromDate.value);
    if (filterToDate.value) searchParams.append('created_to_date', filterToDate.value);

    searchParams.append('only_mine', filterOnlyMine.value.toString());
    searchParams.append('not_synced', filterNotSynced.value.toString());
    searchParams.append('unmatched', filterUnmatched.value.toString());
    searchParams.append('credited_or_deleted', filterCreditedOrDeleted.value.toString());

    const response = await api.invoices.index(searchParams);
    invoices.value = response.data;
    totalSum.value = parseInt(response.total_sum);
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

function onGenerateInvoices() {
  const { open, close, destroy } = useModal({
    component: GenerateInvoicesModal,
    attrs: {
      timePeriods: timePeriods.value,
      async onCreate(timePeriodId: number, { setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.invoices.generate(timePeriodId);
          await getInvoices();
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onCreateInvoice() {
  const { open, close, destroy } = useModal({
    component: CreateInvoiceModal,
    attrs: {
      async onCreate(clientUuid: string, { setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          const response = await api.invoices.store({ client_uuid: clientUuid });
          await router.push({ name: 'invoices.edit', params: { id: response.data.id } });
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onFilter() {
  filterLoader.start();
  currentPage.value = 1;
  await getInvoices();
  filterLoader.finish();
}

async function resetFilters() {
  filterClientUuid.value = '';
  filterUserUuid.value = [];
  filterInternalStatuses.value = [];
  filterExternalStatus.value = [];
  filterFromDate.value = '';
  filterToDate.value = '';
  filterOnlyMine.value = 0;
  filterNotSynced.value = 0;
  filterUnmatched.value = 0;
  filterCreditedOrDeleted.value = 0;
  await onFilter();
}

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('sort_by', 'name');
    searchParams.append('sort_dir', 'asc');
    const response = await api.users.list({ searchParams });
    users.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

const timePeriods = ref<ITimePeriodResource[]>([]);
async function getTimePeriods() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.timePeriods.index({ searchParams });
    timePeriods.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getUsers(), getInvoices(), getTimePeriods()]);
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getInvoices();
  loader.finish();
});

const title = useTitle(computed(() => t('invoice.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="invoice.index.help" />
      </div>
      <AppButton
        @click.prevent="onGenerateInvoices"
        class="ml-auto"
        color="secondary"
        :disabled="loader.isLoading.value"
      >
        {{ t('invoice.index.create_periodical_invoices') }}
      </AppButton>
      <AppButton @click.prevent="onCreateInvoice" class="ml-2" color="secondary" :disabled="loader.isLoading.value">
        {{ t('invoice.index.create_separate_invoice') }}
      </AppButton>
    </div>
    <AppCollapse opened class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-sm-2 row-cols-md-3 rows-cols-lg-4">
          <div class="col form-group">
            <FormLabel html-for="filter_client">
              {{ t('invoice.filters.clients') }}
            </FormLabel>
            <VueSelect
              :filterable="false"
              @search="onSearchClients"
              v-model="filterClientUuid"
              :reduce="(option: IClientPreviewResource) => option.uuid"
              :options="clients"
              label="name"
              input-id="filter_client"
              :placeholder="t('common.search')"
              :clear-search-on-blur="() => true"
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
          <div class="col form-group">
            <FormLabel html-for="filter_user">
              {{ t('invoice.filters.users') }}
            </FormLabel>
            <VueSelect
              v-model="filterUserUuid"
              :reduce="(option: IUserListResource) => option.uuid"
              :options="users"
              label="name"
              input-id="filter_user"
              :placeholder="t('common.search')"
              multiple
              :disabled="usersLoading"
              :loading="usersLoading"
              :clear-search-on-blur="() => true"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="filter_internal_status">
              {{ t('invoice.filters.internal_statuses') }}
            </FormLabel>
            <VueSelect
              v-model="filterInternalStatuses"
              :get-option-label="(option: string) => t(`invoice.internal_status.${option}`)"
              :options="Object.values(InvoiceInternalStatusType)"
              label="label"
              input-id="filter_internal_status"
              :placeholder="t('common.not_selected')"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="filter_external_status">
              {{ t('invoice.filters.external_statuses') }}
            </FormLabel>
            <VueSelect
              v-model="filterExternalStatus"
              :get-option-label="(option: string) => t(`invoice.external_status.${option}`)"
              :options="Object.values(InvoiceExternalStatusType)"
              label="label"
              input-id="filter_external_status"
              :placeholder="t('common.not_selected')"
              multiple
              :clear-search-on-blur="() => true"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="filter_from_date">
              {{ t('invoice.filters.from_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                input-class-name="form-control"
                :placeholder="t('common.not_selected')"
                v-model="filterFromDate"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                week-numbers="iso"
                :max-date="new Date(filterToDate) ?? ''"
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <div class="col form-group">
            <FormLabel html-for="filter_to_date">
              {{ t('invoice.filters.to_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                input-class-name="form-control"
                :placeholder="t('common.not_selected')"
                v-model="filterToDate"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                week-numbers="iso"
                :min-date="new Date(filterFromDate) ?? ''"
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
        </div>
        <div class="row row-cols-1">
          <!-- Show deleted -->
          <div class="form-group col">
            <input
              id="filter_credited_or_deleted"
              type="checkbox"
              class="form-check"
              v-model="filterCreditedOrDeleted"
              :true-value="1"
              :false-value="0"
            />
            <label
              for="filter_credited_or_deleted"
              class="form-label"
              v-text="t('invoice.filters.credited_or_deleted')"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="filterLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <InvoicesTable :total="totalSum" :invoices="invoices" @onDeleted="getInvoices" />
      <template v-if="perPage && total">
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
