import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';

import { BillingFrequency } from '@/types/Common';
import { IBillingCell, IProjectBillingPlan } from '@/types/Project';

export default function useProjectBillingPlans() {
  function createBillingPlans(
    start: string,
    end: string,
    fiscal_start: string,
    fiscal_end: string,
    frequency: BillingFrequency,
    fees: number[],
  ): IProjectBillingPlan[] {
    let plans: IProjectBillingPlan[] = [];
    if (frequency === BillingFrequency.MONTHLY) {
      plans = fees.map((fee, index) => ({
        uid: nanoid(),
        month: DateTime.fromISO(start).plus({ months: index }).month,
        year: DateTime.fromISO(start).plus({ months: index }).year,
        price: fee,
        is_invoiced: false,
      }));
    } else if (frequency === BillingFrequency.QUARTERLY) {
      plans = fees.map((fee, index) => ({
        uid: nanoid(),
        month: DateTime.fromISO(end).minus({ months: index * 3 }).month,
        year: DateTime.fromISO(end).minus({ months: index * 3 }).year,
        price: fee,
        is_invoiced: false,
      }));
    } else if (frequency === BillingFrequency.SEMI_ANNUALLY) {
      const firstScheduled =
        DateTime.now() > DateTime.fromISO(fiscal_end).minus({ months: 3 })
          ? DateTime.now()
          : DateTime.fromISO(fiscal_end).minus({ months: 3 });
      const lastScheduled =
        DateTime.fromISO(end).plus({ months: 3 }) < DateTime.fromISO(fiscal_end)
          ? DateTime.fromISO(end)
          : DateTime.fromISO(fiscal_end).plus({ months: 3 });
      plans = [
        {
          uid: nanoid(),
          month: firstScheduled.month,
          year: firstScheduled.year,
          price: fees[0],
          is_invoiced: false,
        },
        {
          uid: nanoid(),
          month: lastScheduled.month,
          year: lastScheduled.year,
          price: fees[1],
          is_invoiced: false,
        },
      ];
    } else if (frequency === BillingFrequency.ANNUALLY) {
      plans = [
        {
          uid: nanoid(),
          month: DateTime.fromISO(end).month,
          year: DateTime.fromISO(end).year,
          price: fees[0],
          is_invoiced: false,
        },
      ];
    }
    return plans.filter(
      (plan) => DateTime.fromObject({ day: 1, month: plan.month, year: plan.year }) >= DateTime.now().startOf('month'),
    );
  }

  function calculateCompleteMonths(start: string | null, end: string | null) {
    if (start === null || end === null) return 0;
    const startDate = DateTime.fromISO(start).startOf('month');
    const endDate = DateTime.fromISO(end).endOf('month');
    return Math.ceil(endDate.diff(startDate, 'months').months);
  }

  function createBillingCells(start: string, end: string, extraMonths = 12): IBillingCell[] {
    const startDate = DateTime.fromISO(start).startOf('month');
    const months = calculateCompleteMonths(start, end) + extraMonths;
    return Array.from({ length: months }, (_, index) => ({
      uid: nanoid(),
      month: startDate.plus({ months: index }).month,
      year: startDate.plus({ months: index }).year,
    }));
  }

  function splitFee(amount: number | null, parts: number): number[] {
    if (amount === null || amount <= 0) return [];
    const baseAmount = Math.floor(amount / parts);
    const remainder = amount % parts;
    return Array.from({ length: parts }, (_, i) => (i < remainder ? baseAmount + 1 : baseAmount));
  }

  function calculateBillingCount(start: string | null, end: string | null, frequency: BillingFrequency) {
    const months = Math.min(calculateCompleteMonths(start, end), 12);
    switch (frequency) {
      case BillingFrequency.MONTHLY:
        return months;
      case BillingFrequency.QUARTERLY:
        return Math.max(1, Math.ceil(months / 3));
      case BillingFrequency.SEMI_ANNUALLY:
        return 2;
      case BillingFrequency.ANNUALLY:
        return 1;
      default:
        return 1;
    }
  }

  return {
    calculateCompleteMonths,
    calculateBillingCount,
    createBillingCells,
    createBillingPlans,
    splitFee,
  };
}
