<script setup lang="ts">
import { AppButton, AppCloseButton, AppModal, FormLabel, FormTextarea } from '@/components';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { ITodoRequest, ITodoResource, TodoType } from '@/types/Todo';
import VueSelect from 'vue-select';
import useClients from '@/composables/useClients';
import { IClientPreviewResource } from '@/types/Client';
import useUsers from '@/composables/useUsers';
import { onMounted } from 'vue';
import { IUserListResource, IUserPreviewResource, UserStatusType } from '@/types/User';
import VueDatePicker from '@vuepic/vue-datepicker';
import api from '@/services/api';
import toast from '@/services/toast';
import useLoading from '@/composables/useLoading';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import useModalFeatures from '@/composables/useModalFeatures';

type Props = {
  todo?: ITodoResource;
  initialValues?: Partial<ITodoRequest> & { customer?: { uuid: string; name: string } } & {
    recipient?: IUserPreviewResource;
  };
};

const { todo, initialValues } = defineProps<Props>();

const emit = defineEmits<{
  created: [todo: ITodoResource, params: ConfirmDialogConfirmParams];
  updated: [todo: ITodoResource, params: ConfirmDialogConfirmParams];
  closed: [];
  cancel: [];
}>();

const { t, locale } = useI18n({ useScope: 'global' });
const { loading, setLoading } = useLoading();
const { clients, onSearchClients } = useClients();
const { users, usersLoading, getUsers } = useUsers();
const { onCtrlEnter } = useModalFeatures();

const { resetForm, defineField, handleSubmit, meta, setFieldValue } = useForm<ITodoRequest>({
  initialValues: {
    type: initialValues?.type ?? todo?.type ?? TodoType.Todo,
    clientUuid: initialValues?.customer?.uuid ?? todo?.clientUuid ?? null,
    text: initialValues?.text ?? todo?.text ?? '',
    recepientUserUuid: null,
    deadline: initialValues?.deadline ?? todo?.deadline ?? null,
    link: '',
    linkDescription: '',
  },
});

const [type] = defineField('type');
const [customer] = defineField('clientUuid');
const [recipient] = defineField('recepientUserUuid');
const [deadline] = defineField('deadline');
const [text] = defineField('text');

const submit = handleSubmit(async (values) => {
  try {
    setLoading(true);
    if (todo) {
      const response = await api.todos.update(todo.id, values);
      toast.success(t('common.updated'));
      emit('updated', response, { setLoading });
    } else {
      const response = await api.todos.create(values);
      toast.success(t('common.created'));
      emit('created', response, { setLoading });
    }
    resetForm();
  } catch (error) {
    console.error(error);
  }
});

onMounted(async () => {
  if (todo) {
    if (todo.client) {
      clients.value = [todo.client];
    }
  }
  if (initialValues?.customer) {
    clients.value = [initialValues.customer as IClientPreviewResource];
  }
  if (initialValues?.recipient) {
    users.value = [initialValues.recipient as IUserListResource];
  } else {
    await getUsers({
      searchParams: {
        without_pagination: 1,
        status: UserStatusType.Active,
      },
    });
    setFieldValue('recepientUserUuid', initialValues?.recipient?.uuid ?? todo?.recepientUserUuid ?? null);
  }
});

onCtrlEnter(() => {
  if (meta.value.valid) {
    submit();
  } else {
    emit('cancel');
  }
});
</script>

<template>
  <AppModal @closed="emit('closed')" v-slot="{ close }">
    <form @submit="submit">
      <div class="modal-header">
        <h2 v-text="todo ? t('todo.edit.title') : t('todo.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <div class="row">
          <!-- Type -->
          <div class="form-group col-md-6">
            <FormLabel html-for="todo_type" required>
              {{ t('todo.attributes.type') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              :filterable="false"
              :searchable="false"
              v-model="type"
              :options="Object.values(TodoType)"
              :get-option-label="(option: TodoType) => t(`todo.type.${option}`)"
              input-id="todo_type"
            />
          </div>
          <!-- Deadline -->
          <div class="form-group col-md-6">
            <FormLabel html-for="dp-input-todo_deadline">
              {{ t('todo.attributes.deadline') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="deadline"
                input-class-name="form-control"
                :placeholder="t('common.select')"
                v-model="deadline"
                model-type="format"
                auto-apply
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                :min-date="new Date()"
                text-input
                :locale="locale"
                :disabled="!!initialValues?.deadline"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Customer -->
          <div class="form-group col-md-6">
            <FormLabel html-for="todo_customer">
              {{ t('todo.attributes.client') }}
            </FormLabel>
            <VueSelect
              :filterable="false"
              @search="onSearchClients"
              v-model="customer"
              :reduce="(option: IClientPreviewResource) => option.uuid"
              :options="clients"
              :disabled="!!initialValues?.customer"
              label="name"
              input-id="todo_customer"
              :placeholder="t('common.search')"
              :clear-search-on-blur="() => true"
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
          <!-- Recipient -->
          <div class="form-group col-md-6">
            <FormLabel html-for="todo_recipient">
              {{ t('todo.attributes.recipient') }}
            </FormLabel>
            <VueSelect
              :clearable="true"
              v-model="recipient"
              :loading="usersLoading"
              :options="users"
              :disabled="!!initialValues?.recipient || usersLoading"
              label="name"
              :reduce="(option: IUserListResource) => option.uuid"
              input-id="todo_recipient"
              :placeholder="usersLoading ? t('common.loading') : t('common.all')"
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
          <!-- Text -->
          <div class="form-group col">
            <FormLabel html-for="todo_text">
              {{ t('todo.attributes.text') }}
            </FormLabel>
            <FormTextarea v-model="text" id="todo_text" rows="5" />
          </div>
        </div>

        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="ml-auto" :color="todo ? 'secondary' : 'success'" :disabled="loading" :loading="loading">
            {{ todo ? t('common.update') : t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </AppModal>
</template>
