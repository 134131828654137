export default {
  index: {
    title: 'Tidrapporter att hantera',
    help: '',
  },
  show: {
    title: 'Tidrapport för {username}',
    help: '',
  },
  form: {
    status: 'Status',
  },

  empty: 'Inga tidrapporter',

  // Attributes
  attributes: {
    total_tracked_time: 'Summa rapporterad tid',
    time_period: 'Månad',
    status: 'Status',
    review_status: 'Granskningsstatus',
    user: 'Användare',
    locked: 'Låst',
    approved: 'Godkänd',
    time_report_status: 'Tidrapportstatus',
    review_comment: 'Granskningskommentar',
  },

  status: {
    unlocked: 'Öppen',
    locked: 'Låst',
  },

  review_status: {
    not_reviewed: 'Ej granskad',
    not_approved: 'Ej godkänd',
    approved: 'Godkänd',
  },

  time_report_status: {
    completed: 'Färdig',
    not_completed: 'Ej färdig',
  },

  tooltip: {
    lock: 'Lås',
    unlock: 'Lås upp',
    approve: 'Godkänn',
    not_approve: 'Ej godkänd',
  },

  confirm: {
    approve: {
      title: 'Godkänns tidrapporten',
      text: 'Är du säker?',
    },
    lock: {
      title: 'Lås tidrapporten',
      text: 'Är du säker?',
    },
    unlock: {
      title: 'Lås upp tidrapporten',
      text: 'Är du säker?',
    },
  },

  reported: {
    title: 'Min rapporterade tid',
  },
  parental: {
    title: 'Min föräldraledighet',
  },
  childcare: {
    title: 'Min VAB',
  },
  review: {
    general_info: 'Allmän info',
    reported_hours: 'Rapporterade timmar',
    weekly_reported: 'Veckans rapporterade tid',
    manager_review: 'Granskning av manager',

    attributes: {
      month: 'Månad',
      time_sheet: 'Tidrapport',
      status: 'Låst',
      review_status: 'Granskningsstatus',
      scheduled_time_for_the_month: 'Schemalagda timmar för månaden',
      overtime_for_the_month: 'Rapporterat v Schemalagt för månaden',
      flex_time: 'Summa flextid',
      total_time: 'Reporterad tid för månaden',
      client_time: 'Kundtid',
      internal_time: 'Interntid',
      absence: 'Frånvarotid',
    },
  },
};
