<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useRouter } from 'vue-router';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

import useLoader from '@/composables/useLoader';
import { IServiceCompanyListResource } from '@/types/ServiceCompany';
import api from '@/services/api';
import {
  AppButton,
  AppBox,
  AppBoxBody,
  FormLabel,
  FormInput,
  AppLoader,
  AppPagination,
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  FontIcon,
  HelpInformation,
  ColumnSettings,
  ConfirmDialog,
} from '@/components';
import useColumnSettings from '@/composables/useColumnSettings';
import { Dropdown } from 'floating-vue';
import { useTitle } from '@vueuse/core';

const { t } = useI18n({ useScope: 'global' });
const router = useRouter();
const loader = useLoader();

const serviceCompanies = ref<IServiceCompanyListResource[]>([]);
const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);
const filters = reactive({
  dirty: false,
  search: '',
});

const { columns, isColumnEnable, enabledColumns } = useColumnSettings('service_companies_columns', [
  'main_contact',
  'phone',
  'email',
  'website',
  'shared_client',
  'partner_discount',
  'responsible_user',
]);

const filterDisabled = computed(() => loader.isLoading.value);
const filterApplyDisabled = computed(() => filters.search === '');

async function getServiceCompanies() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.search) searchParams.append('search', filters.search);
    const response = await api.serviceCompanies.list({ searchParams });
    serviceCompanies.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onRowClick(serviceCompany: IServiceCompanyListResource) {
  await router.push({ name: 'service-organizations.edit', params: { uuid: serviceCompany.uuid } });
}

function onDelete(serviceCompany: IServiceCompanyListResource) {
  const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
    title: t('service-company.confirm.destroy.title'),
    message: t('service-company.confirm.destroy.text', { name: serviceCompany.name }),
  });

  reveal();

  onConfirm(async () => {
    try {
      await api.serviceCompanies.destroy(serviceCompany.uuid);
      await getServiceCompanies();
      if (serviceCompanies.value.length === 0 && currentPage.value > 1) {
        currentPage.value -= 1;
      }
    } catch (error) {
      console.error(error);
    }
  });
}

function onUnlink(serviceCompany: IServiceCompanyListResource) {
  const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
    title: t('service-company.confirm.unlink.title'),
    message: t('service-company.confirm.unlink.text', { name: serviceCompany.name }),
  });

  reveal();

  onConfirm(async () => {
    try {
      await api.serviceCompanies.unlink(serviceCompany.uuid);
      await getServiceCompanies();
      if (serviceCompanies.value.length === 0 && currentPage.value > 1) {
        currentPage.value -= 1;
      }
    } catch (error) {
      console.error(error);
    }
  });
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getServiceCompanies();
  loader.finish();
  filters.dirty = true;
}

async function resetFilters() {
  filters.search = '';
  await onFilter();
  filters.dirty = false;
}

onMounted(async () => {
  loader.start();
  await getServiceCompanies();
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getServiceCompanies();
  loader.finish();
});

const title = useTitle(computed(() => t('service-company.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="service-company.index.help" />
      </div>
      <AppButton
        class="ml-auto"
        @click.prevent="router.push({ name: 'service-organizations.create' })"
        color="secondary"
      >
        {{ t('service-company.index.create') }}
      </AppButton>
      <Dropdown placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`service-company.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row d-flex align-items-end">
            <div class="col-3">
              <FormLabel html-for="filter_name">
                {{ t('service-company.index.filters.name') }}
              </FormLabel>
              <FormInput
                type="search"
                v-model.trim="filters.search"
                id="filter_name"
                icon="search"
                :disabled="filterDisabled"
              />
            </div>
            <div class="col-9">
              <AppButton :disabled="filterDisabled || filterApplyDisabled">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterDisabled || !filters.dirty">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="serviceCompanies.length === 0">
        {{ filters.dirty ? t('common.no_search_results') : t('service-company.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>
                {{ t('service-company.attributes.name') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('main_contact')">
                {{ t('service-company.attributes.main_contact') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('phone')">
                {{ t('service-company.attributes.phone') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('email')">
                {{ t('service-company.attributes.email') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('website')">
                {{ t('service-company.attributes.website') }}
              </AppTableTh>
              <AppTableTh class="text-right" nowrap v-if="isColumnEnable('shared_client')">
                {{ t('service-company.attributes.shared_client') }}
              </AppTableTh>
              <AppTableTh class="text-right" nowrap v-if="isColumnEnable('partner_discount')">
                {{ t('service-company.attributes.partner_discount') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('responsible_user')">
                {{ t('service-company.attributes.responsible_user') }}
              </AppTableTh>
              <AppTableTh class="text-right" nowrap>
                {{ t('common.actions') }}
              </AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              class="pointer"
              @click="onRowClick(serviceCompany)"
              v-for="serviceCompany in serviceCompanies"
              :key="serviceCompany.uuid"
            >
              <AppTableTd nowrap><strong v-text="serviceCompany.name" /></AppTableTd>
              <AppTableTd v-if="isColumnEnable('main_contact')">
                <span v-if="serviceCompany.main_contact" v-text="serviceCompany.main_contact.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('phone')">
                <span v-if="serviceCompany.phone" v-text="serviceCompany.phone" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('email')">
                <span v-if="serviceCompany.email" v-text="serviceCompany.email" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('website')">
                <span v-if="serviceCompany.website" v-text="serviceCompany.website" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap v-if="isColumnEnable('shared_client')">
                <i v-if="serviceCompany.shared_clients === 0" class="text-neutral-300">0</i>
                <RouterLink
                  @click.stop
                  v-else
                  target="_blank"
                  :to="{ name: 'clients.index', query: { service_company: serviceCompany.uuid, show_only_active: 0 } }"
                  class="pointer"
                >
                  {{ serviceCompany.shared_clients }} ({{ t('service-company.index.see_clients') }})
                </RouterLink>
              </AppTableTd>
              <AppTableTd class="text-right" nowrap v-if="isColumnEnable('partner_discount')">
                <span v-if="serviceCompany.partner_discount">{{ serviceCompany.partner_discount }}%</span>
                <i v-else class="text-neutral-300">0%</i>
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('responsible_user')">
                <span v-if="serviceCompany.responsible_user" v-text="serviceCompany.responsible_user.name" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <AppButton
                  v-tooltip.left="t('service-company.tooltip.edit', { name: serviceCompany.name })"
                  @click.stop.prevent="
                    router.push({ name: 'service-organizations.edit', params: { uuid: serviceCompany.uuid } })
                  "
                  size="small"
                  light
                  circle
                  class="ml-2"
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-if="serviceCompany.has_linked_companies"
                  v-tooltip.left="t('service-company.tooltip.unlink')"
                  @click.stop.prevent="onUnlink(serviceCompany)"
                  size="small"
                  light
                  circle
                  class="ml-2"
                  color="danger"
                >
                  <FontIcon name="link-off" />
                </AppButton>
                <AppButton
                  v-if="!serviceCompany.has_linked_companies"
                  v-tooltip.left="t('service-company.tooltip.destroy')"
                  @click.stop.prevent="onDelete(serviceCompany)"
                  size="small"
                  light
                  circle
                  class="ml-2"
                  color="danger"
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
