<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { inject, ref, Ref } from 'vue';

import { AppBox, AppBoxBody, AppButton, FontIcon, PlanningProgressMultiBar } from '@/components';
import useTime from '@/composables/useTime';
import { IEventTimelineWeek } from '@/types/Event';

const { expanded, weekNumber, data, loadingActivitiesToDone } = defineProps<{
  expanded: boolean;
  weekNumber: string;
  data: IEventTimelineWeek['activities'];
  loadingActivitiesToDone: number[];
}>();

const emit = defineEmits<{
  (e: 'toggle', week: string): void;
  (e: 'edit', id: number): void;
  (e: 'done', id: number): void;
}>();

const { convertMinutesToTime } = useTime();
const { t } = useI18n({ useScope: 'global' });
const hideDone = inject<Ref<number>>('hide_done', ref(0));

function dragstart(dragEvent: DragEvent, id: number) {
  dragEvent.stopPropagation();
  if (!dragEvent.dataTransfer) return;
  (dragEvent.target as HTMLDivElement).classList.add('dragging');
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('from_week', weekNumber);
  dragEvent.dataTransfer.setData('source', 'activity');
  dragEvent.dataTransfer.setData('events', id.toString());
}

function dragend(dragEvent: DragEvent) {
  dragEvent.stopPropagation();
  (dragEvent.target as HTMLDivElement).classList.remove('dragging');
}
</script>

<template>
  <AppBox
    v-if="data.events.length && !(hideDone && data.events.every((event) => event.done_at))"
    style="margin-top: 1px"
    shadow
  >
    <AppBoxBody>
      <div class="row d-flex align-items-center">
        <div class="col-3 mb-0 font-bold text-4">
          {{ t('dashboard.index.firm_activities') }}
        </div>
        <div class="col 1 text-right">
          <AppButton size="small" @click.prevent="emit('toggle', weekNumber)" light circle>
            <FontIcon v-if="expanded" name="chevron-up" />
            <FontIcon v-else name="chevron-down" />
          </AppButton>
        </div>
        <div class="col-1 text-center">
          <strong class="text-5" v-text="convertMinutesToTime(data.time.budgeted)" />
        </div>
        <div class="col-1" />
        <div class="col-6">
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </div>
      </div>
      <div class="mt-2" v-if="expanded">
        <div
          class="row align-items-center py-3"
          v-for="activity in data.events"
          :key="activity.id"
          :class="[{ draggable: !activity.done_at }, hideDone && activity.done_at ? 'd-none' : 'd-flex']"
          :draggable="!activity.done_at"
          @dragstart="dragstart($event, activity.id)"
          @dragend="dragend"
        >
          <div class="col-4">
            <strong
              class="pointer text-secondary-500 text-5 pl-2"
              @click.prevent="emit('edit', activity.id)"
              v-text="activity.name"
            />
          </div>
          <div class="col-1 text-center">
            <strong class="text-5" v-text="convertMinutesToTime(activity.time.budgeted)" />
          </div>
          <div class="col-1">
            <FontIcon v-if="activity.done_at" class="text-success-500 text-2 ml-3" name="check" />
          </div>
          <div class="col-6 d-flex align-items-center">
            <div class="flex-grow-1">
              <PlanningProgressMultiBar
                :green="activity.time.finished"
                :orange="activity.time.reported"
                :gray="Math.max(activity.time.remaining, 0)"
              />
            </div>
            <div v-if="!activity.done_at" class="flex-shrink-0 pl-2">
              <AppButton
                color="success"
                circle
                light
                size="small"
                @click.prevent="emit('done', activity.id)"
                :loading="loadingActivitiesToDone.includes(activity.id)"
              >
                <FontIcon name="check" />
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </AppBoxBody>
  </AppBox>
</template>
