<script setup lang="ts">
import { onMounted, provide, Ref, ref, shallowRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useRouteParams } from '@vueuse/router';
import { useLocalStorage, useTitle } from '@vueuse/core';
import { useModal } from 'vue-final-modal';
import { Dropdown } from 'floating-vue';
import {
  AppButton,
  AppLoader,
  FontIcon,
  TimeEntryActivityModal,
  TimeEntryDropdownOptions,
  TimeEntryProjectTaskModal,
  TimelineActivities,
  TimelineClient,
  TimelineProject,
  TimeSheetTable,
  TimelineWeek,
  UserDeadlines,
  UserTodos,
} from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useAuthStore from '@/store/AuthStore';
import { IEventTimelineWeek } from '@/types/Event';
import { ActivityModalProps } from '@/types/Activity';
import { ProjectTaskModalProps } from '@/types/ProjectTask';
import { IUserResource } from '@/types/User';
import useExpandedList from '@/composables/useExpandedList';

const { getCurrentYearAndWeek } = useDate();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const actionLoader = useLoader();
const weekStartLoader = useLoader();
const weekEndLoader = useLoader();
const { authenticatedUser } = useAuthStore();
const route = useRoute();
useTitle(computed(() => t('dashboard.index.title')));

const hideDone = useLocalStorage<number>('we_plan_dashboard_hide_done', 0);
const showAllTasks = useLocalStorage<number>('we_plan_dashboard_show_all_tasks', 0);

const userUuid = useRouteParams('uuid', authenticatedUser.uuid);

const isPersonal = computed(() => !route.params.uuid);

const userDeadlinesComponent = shallowRef<InstanceType<typeof UserDeadlines>>();

const loadingActivitiesToDone = ref<number[]>([]);
const loadingProjectTasksToDone = ref<number[]>([]);
const loadingWeeksForward = ref<string[]>([]);

const collapsedProjects = ref<string[]>([]);
const weekStart = ref(-1);
const weekEnd = ref(0);
const timeline = ref<Record<string, IEventTimelineWeek>>({});

const { isExpanded: isExpandedWeek, expand: expandWeek, toggle: toggleWeek } = useExpandedList();
const { isExpanded: isExpandedClient, toggle: toggleClient } = useExpandedList();
const { isExpanded: isExpandedTimeSheet, expand: expandTimeSheet, toggle: toggleTimeSheet } = useExpandedList();
const { isExpanded: isExpandedActivity, toggle: toggleActivity } = useExpandedList();

function onProjectTaskCreate(attrs: ProjectTaskModalProps) {
  const { open, close, destroy } = useModal({
    component: TimeEntryProjectTaskModal,
    attrs: {
      ...attrs,
      async onCreated() {
        await Promise.all([getTimeline(), userDeadlinesComponent.value?.getDeadlines()]);
        await close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onProjectTaskEdit(id: number) {
  const { open, close, destroy } = useModal({
    component: TimeEntryProjectTaskModal,
    attrs: {
      id,
      async onSplit() {
        await Promise.all([getTimeline(), userDeadlinesComponent.value?.getDeadlines()]);
        await close();
      },
      async onUpdated() {
        await Promise.all([getTimeline(), userDeadlinesComponent.value?.getDeadlines()]);
        await close();
      },
      async onDeleted() {
        await Promise.all([getTimeline(), userDeadlinesComponent.value?.getDeadlines()]);
        await close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onProjectTaskDone(idToDone: number) {
  try {
    actionLoader.start();
    loadingProjectTasksToDone.value.push(idToDone);
    await api.events.done(idToDone);
    await getTimeline();
  } catch (error) {
    console.error(error);
  } finally {
    loadingProjectTasksToDone.value = loadingProjectTasksToDone.value.filter((id) => id !== idToDone);
    actionLoader.finish();
  }
}

const user = ref<IUserResource>();
async function getUser(uuid: string) {
  try {
    const response = await api.users.get(uuid);
    user.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function onActivityCreate(attrs: ActivityModalProps) {
  const { open, close, destroy } = useModal({
    component: TimeEntryActivityModal,
    attrs: {
      ...attrs,
      async onCreated() {
        await getTimeline();
        await close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onActivityEdit(id: number) {
  const { close, open, destroy } = useModal({
    component: TimeEntryActivityModal,
    attrs: {
      id,
      async onSplit() {
        await getTimeline();
        await close();
      },
      async onUpdated() {
        await getTimeline();
        await close();
      },
      async onDeleted() {
        await getTimeline();
        await close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onActivityDone(idToDone: number) {
  try {
    actionLoader.start();
    loadingActivitiesToDone.value.push(idToDone);
    await api.events.done(idToDone);
    await getTimeline();
  } catch (error) {
    console.error(error);
  } finally {
    loadingActivitiesToDone.value = loadingActivitiesToDone.value.filter((id) => id !== idToDone);
    actionLoader.finish();
  }
}

function toggleProject(uniqueId: string) {
  if (collapsedProjects.value.includes(uniqueId)) {
    collapsedProjects.value = collapsedProjects.value.filter((item) => item !== uniqueId);
  } else {
    collapsedProjects.value.push(uniqueId);
  }
}

async function getTimeline(withRange = true) {
  try {
    const searchParams = new URLSearchParams();
    if (withRange) {
      searchParams.append('weekStart', weekStart.value.toString());
      searchParams.append('weekEnd', weekEnd.value.toString());
    }
    const response = await api.users.events.timeline(userUuid.value, { searchParams });
    timeline.value = response.data;
    if (!withRange) {
      weekStart.value = -(Object.keys(response.data).length - 1);
    }
  } catch (error) {
    console.error(error);
  }
}

function isAllTasksDone(project: IEventTimelineWeek['tasks']['events'][0]['projects'][0]) {
  return project.tasks.every((task) => !!task.done_at);
}

async function loadStartWeek() {
  try {
    weekStartLoader.start();
    const week = weekStart.value - 1;
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', week.toString());
    searchParams.append('weekEnd', week.toString());
    const response = await api.users.events.timeline(userUuid.value, { searchParams });
    weekStart.value = week;
    timeline.value = { ...response.data, ...timeline.value };
  } catch (error) {
    console.error(error);
  } finally {
    weekStartLoader.finish();
  }
}

async function loadEndWeek() {
  try {
    weekEndLoader.start();
    const week = weekEnd.value + 1;
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', week.toString());
    searchParams.append('weekEnd', week.toString());
    const response = await api.users.events.timeline(userUuid.value, { searchParams });
    weekEnd.value = week;
    timeline.value = { ...timeline.value, ...response.data };
  } catch (error) {
    console.error(error);
  } finally {
    weekEndLoader.finish();
  }
}

async function onMoveWeekForward(week: string) {
  try {
    loadingWeeksForward.value.push(week);
    const searchParams = new URLSearchParams();
    searchParams.append('week', week);
    searchParams.append('user_uuid', authenticatedUser.uuid);
    await api.events.moveWeekForward({ searchParams });
    await getTimeline();
  } catch (error) {
    console.error(error);
  } finally {
    loadingWeeksForward.value = loadingWeeksForward.value.filter((w) => w !== week);
  }
}

async function onDropEvents(events: number[], toWeek: string) {
  try {
    await api.events.move({ ids: events, week: Number(toWeek) });
    await getTimeline();
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  if (route.params.uuid && typeof route.params.uuid === 'string') {
    await getUser(route.params.uuid);
  }
  await getTimeline(false);
  expandWeek(getCurrentYearAndWeek().toString());
  expandTimeSheet(getCurrentYearAndWeek().toString());
  loader.finish();
});

provide<Ref<number>>('hide_done', hideDone);
provide<Ref<number>>('show_all_tasks', showAllTasks);
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <div v-else>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h2 v-if="isPersonal" class="mb-0" v-text="t('dashboard.index.title')" />
            <h2 v-else class="mb-0" v-text="user?.name" />
            <Dropdown placement="bottom-end" :distance="10">
              <AppButton color="secondary">
                {{ t('dashboard.index.create') }}
                <FontIcon name="chevron-down" />
              </AppButton>
              <template #popper="{ hide }">
                <TimeEntryDropdownOptions
                  @on-absence="onActivityCreate($event)"
                  @on-project-task="onProjectTaskCreate($event)"
                  @on-internal="onActivityCreate($event)"
                  :hide="hide"
                />
              </template>
            </Dropdown>
          </div>
          <!-- Filters -->
          <div class="d-flex">
            <div class="form-group">
              <input
                id="hide_done"
                type="checkbox"
                class="form-check"
                v-model="hideDone"
                :true-value="1"
                :false-value="0"
              />
              <label for="hide_done" class="form-label" v-text="t('dashboard.index.hide_done')" />
            </div>
            <div class="form-group ml-4">
              <input
                id="show_all_tasks"
                type="checkbox"
                class="form-check"
                v-model="showAllTasks"
                :true-value="1"
                :false-value="0"
              />
              <label for="show_all_tasks" class="form-label" v-text="t('dashboard.index.show_all_tasks')" />
            </div>
          </div>
          <!-- /Filters -->
          <!-- Load start week start -->
          <AppButton
            class="mb-3"
            light
            size="small"
            @click.prevent="loadStartWeek"
            :loading="weekStartLoader.isLoading.value"
            :disabled="weekStartLoader.isLoading.value || weekEndLoader.isLoading.value"
          >
            {{ t('dashboard.buttons.load_prev_week') }}
            <FontIcon name="chevrons-up" />
          </AppButton>
          <!-- Load start week end -->

          <!-- Weeks start -->
          <TransitionGroup name="list" tag="div">
            <div class="mb-3" v-for="(week, weekNumber) in timeline" :key="weekNumber">
              <TimelineWeek
                :expanded="isExpandedWeek(weekNumber)"
                :data="week"
                :week-number="weekNumber"
                @toggle="toggleWeek($event)"
                @move-week-forward="onMoveWeekForward($event)"
                :is-current="getCurrentYearAndWeek() === +weekNumber"
                :is-completed="week.is_completed"
                :is-forward-week-loading="loadingWeeksForward.includes(weekNumber)"
                @drop-events="onDropEvents"
              >
                <div v-if="isExpandedWeek(weekNumber)">
                  <!-- Project tasks -->
                  <div style="margin-top: 1px" v-for="client in week.tasks.events" :key="client.uuid + weekNumber">
                    <TimelineClient
                      :expanded="isExpandedClient(client.uuid + weekNumber)"
                      :data="client"
                      :user-working-time-minutes="week.user_working_time_minutes"
                      @toggle="toggleClient($event + weekNumber)"
                      v-if="!(hideDone && client.projects.every((project) => isAllTasksDone(project)))"
                      :week-number="weekNumber"
                    />
                    <template v-if="isExpandedClient(client.uuid + weekNumber)">
                      <div style="margin-top: 1px" v-for="project in client.projects" :key="project.id">
                        <TimelineProject
                          :client-uuid="client.uuid"
                          :user-uuid="userUuid"
                          :data="project"
                          :week-number="weekNumber"
                          :collapsed="collapsedProjects.includes(project.id + client.uuid + weekNumber)"
                          :loading-tasks-to-done="loadingProjectTasksToDone"
                          @toggle="toggleProject($event + client.uuid + weekNumber)"
                          @edit="onProjectTaskEdit($event)"
                          @done="onProjectTaskDone($event)"
                        />
                      </div>
                    </template>
                  </div>
                  <!-- Internal activities -->
                  <TimelineActivities
                    :expanded="isExpandedActivity(weekNumber + 'activity')"
                    :data="week.activities"
                    :week-number="weekNumber"
                    :loading-activities-to-done="loadingActivitiesToDone"
                    @toggle="toggleActivity($event + 'activity')"
                    @edit="onActivityEdit($event)"
                    @done="onActivityDone($event)"
                  />

                  <!-- TimeSheet -->
                  <div style="border-top: 1px solid var(--color-neutral-200-hex)">
                    <TimeSheetTable
                      :expanded="isExpandedTimeSheet(weekNumber)"
                      :data="week.time_sheet"
                      :week="weekNumber"
                      :show-all-tasks="Boolean(showAllTasks)"
                      @toggle="toggleTimeSheet($event)"
                      @activity-create="onActivityCreate($event)"
                      @project-task-create="onProjectTaskCreate($event)"
                      @activity-edit="onActivityEdit($event)"
                      @project-task-edit="onProjectTaskEdit($event)"
                    />
                  </div>
                </div>
              </TimelineWeek>
            </div>
          </TransitionGroup>
          <!-- Weeks end -->

          <!-- Load end week start -->
          <AppButton
            class="mt-2"
            light
            size="small"
            @click.prevent="loadEndWeek"
            :loading="weekEndLoader.isLoading.value"
            :disabled="weekEndLoader.isLoading.value || weekStartLoader.isLoading.value"
          >
            {{ t('dashboard.buttons.load_next_week') }}
            <FontIcon name="chevrons-down" />
          </AppButton>
          <!-- Load end week end -->
        </div>

        <!-- ToDos -->
        <div class="row mt-4">
          <div class="col-md-6">
            <UserTodos type="todos" :title="t('user.todos.todos.title')" :user-uuid="userUuid" />
          </div>
          <div class="col-md-6">
            <UserTodos type="infos" :title="t('user.todos.informations.title')" :user-uuid="userUuid" />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <UserDeadlines
          ref="userDeadlinesComponent"
          :user-uuid="userUuid"
          :week-number="getCurrentYearAndWeek()"
          @updated="getTimeline"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.draggable {
  cursor: pointer;
  user-select: none;
  &.dragging {
    opacity: 0.25;
    background-color: white;
  }
}
.droppable {
  &.over {
    background-color: var(--color-neutral-100);
  }
}
</style>
