<script lang="ts" setup>
import { ref, computed, nextTick } from 'vue';
import { clsx } from 'clsx';
import { Decimal } from 'decimal.js';

import { AppButton, FontIcon } from '@/components';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['change']);
const input = ref<HTMLInputElement>();

type Props = {
  size?: null | 'small' | 'large';
  max?: number;
  min?: number;
  disabled?: boolean;
  step?: number;
  length?: number;
  decimalPlaces?: number;
  float?: boolean;
};

const {
  size = null,
  max = 5985,
  min = 0,
  step = 1,
  length = 4,
  decimalPlaces = 0,
  disabled,
  float = false,
} = defineProps<Props>();

const modelValue = defineModel<number | null>({
  required: true,
});

function increase(value: number) {
  let newValue = (modelValue.value ?? 0) + value >= max ? max : (modelValue.value ?? 0) + value;
  newValue = new Decimal(newValue).toDecimalPlaces(decimalPlaces).toNumber();
  modelValue.value = newValue;
  emit('change', newValue);
}

function decrease(value: number) {
  let newValue = (modelValue.value ?? 0) - value <= min ? min : (modelValue.value ?? 0) - value;
  newValue = new Decimal(newValue).toDecimalPlaces(decimalPlaces).toNumber();
  modelValue.value = newValue;
  emit('change', newValue);
}

async function onChange(event: Event) {
  const input = event.target as HTMLInputElement;
  const rawValue = Number[float ? 'parseFloat' : 'parseInt'](input.value);
  const normalizedValue = isNaN(rawValue) || !isFinite(rawValue) ? 0 : rawValue;
  const value = new Decimal(normalizedValue).toDecimalPlaces(decimalPlaces).toNumber();
  if (value >= max) {
    input.value = max.toString();
    modelValue.value = max;
  } else if (value <= min) {
    input.value = min.toString();
    modelValue.value = min;
  } else {
    input.value = value.toString();
    modelValue.value = value;
  }
  await nextTick();
  emit('change', modelValue.value);
}

const classes = computed(() =>
  clsx('form-wrapper', 'd-flex', 'align-items-center', {
    [`is-${size}`]: size,
  }),
);
</script>

<template>
  <div :class="classes">
    <AppButton
      @click.prevent="decrease(step)"
      type="button"
      light
      circle
      size="small"
      class="mr-1"
      :disabled="disabled || (modelValue ?? 0) <= min"
    >
      <FontIcon name="minus" />
    </AppButton>
    <input
      v-bind="$attrs"
      class="form-control text-center w-auto"
      :value="modelValue"
      @keydown.enter.prevent="input?.blur"
      placeholder="0"
      :size="length"
      :disabled="disabled"
      @change="onChange"
      ref="input"
    />
    <AppButton
      @click.prevent="increase(step)"
      type="button"
      light
      circle
      size="small"
      class="ml-1"
      :disabled="disabled || (modelValue ?? 0) >= max"
    >
      <FontIcon name="plus" />
    </AppButton>
  </div>
  <slot />
</template>
